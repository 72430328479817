import React,{useState} from "react";
import {
  StyledBackground,
  StyledCard,
  StyledCard1,
  StyledDesign,
  StyledH1,
  StyledH3,
  StyledIcon,
  StyledIcon1,
  StyledMain,
  StyledP,
  StyledP1,
  StyledP5,
  StyledRow,
  StyledRow1,
  StyledServices,
  StyledText,
  StyledTextSection,
  StyledButton,
  Button1,
} from "./styles";
import { FaArrowRight } from "react-icons/fa6";
import { MdAdsClick, MdOutlineAcUnit } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { GrSend } from "react-icons/gr";
import { LuPackage } from "react-icons/lu";
import { MdMotionPhotosOn } from "react-icons/md";
import { SiMaterialdesign } from "react-icons/si";
import { FaPaintBrush } from "react-icons/fa";
import VideoPopup from '../VideoPopup';
import AboutVideo from "./AboutVideo";
function Services() {
 const videoSrc = "./DesignerVideo2.mp4";
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  }
  return (
    <StyledMain>
      <StyledDesign id="about">
        <StyledBackground src="/image11.png" alt="img"></StyledBackground>
        <StyledTextSection>
          <StyledH1>
            Turning <span> Visions </span> into Stories, Pixel by Pixel
          </StyledH1>
          <StyledP5>
            At Liam Crest Design, we're more than just creators; we're
            architects of visual storytelling. With a fusion of creativity and
            technical expertise, our team specializes in{" "}
            <span> crafting immersive experiences </span>that leave a lasting
            impact. From concept ideation to polished execution, we thrive on
            transforming ideas into captivating designs across print, digital,
            and experiential platforms. With a commitment to excellence and a
            passion for pushing boundaries, Liam Crest Design is your trusted
            partner in shaping the visual landscape of tomorrow.
          </StyledP5>
          {/* <StyledButton>
            {isModalOpen && <VideoPopup onClose={toggleModal} />}
            <Button1 onClick={toggleModal}>
              Explore{" "}
              <FaArrowRight
                style={{ color: "#ffffff", position: "relative", top: "5%" }}
              ></FaArrowRight>
            </Button1>
          </StyledButton> */}
        </StyledTextSection>
      </StyledDesign>
      <AboutVideo videoSrc={videoSrc} autoplay={true} />
      <StyledText id="Services">
        <StyledP>OUR BEST SERVICES</StyledP>
        <StyledH3>What Services We Are Offering</StyledH3>
      </StyledText>
      <StyledServices>
        <StyledRow>
          <StyledCard>
            <StyledIcon>
              <StyledIcon1>
                <MdAdsClick style={{ width: "100%", height: "100%" }} />
              </StyledIcon1>
            </StyledIcon>
            <StyledP1>Marketing & Advertising Graphic Design</StyledP1>
          </StyledCard>
          <StyledCard>
            <StyledIcon>
              <StyledIcon1>
                <MdOutlineAcUnit style={{ width: "100%", height: "100%" }} />
              </StyledIcon1>
            </StyledIcon>
            <StyledP1>Visual Identity Graphic Designs</StyledP1>
          </StyledCard>
          <StyledCard>
            <StyledIcon>
              <StyledIcon1>
                <IoSettingsOutline style={{ width: "100%", height: "100%" }} />
              </StyledIcon1>
            </StyledIcon>
            <StyledP1>User Interface Graphic Design</StyledP1>
          </StyledCard>
          <StyledCard1>
            <StyledIcon>
              <StyledIcon1>
                <GrSend style={{ width: "100%", height: "100%" }} />
              </StyledIcon1>
            </StyledIcon>
            <StyledP1>Publication Graphic Design</StyledP1>
          </StyledCard1>
        </StyledRow>
        <StyledRow>
          <StyledCard>
            <StyledIcon>
              <StyledIcon1>
                <LuPackage style={{ width: "100%", height: "100%" }} />
              </StyledIcon1>
            </StyledIcon>
            <StyledP1>Packaging Graphic Design</StyledP1>
          </StyledCard>
          <StyledCard>
            <StyledIcon>
              <StyledIcon1>
                <MdMotionPhotosOn style={{ width: "100%", height: "100%" }} />
              </StyledIcon1>
            </StyledIcon>
            <StyledP1>Motion Graphic Design</StyledP1>
          </StyledCard>
          <StyledCard>
            <StyledIcon>
              <StyledIcon1>
                <SiMaterialdesign style={{ width: "100%", height: "100%" }} />
              </StyledIcon1>
            </StyledIcon>
            <StyledP1>Environmental Graphic Design</StyledP1>
          </StyledCard>
          <StyledCard1>
            <StyledIcon>
              <StyledIcon1>
                <FaPaintBrush style={{ width: "100%", height: "100%" }} />
              </StyledIcon1>
            </StyledIcon>
            <StyledP1>Art and Illustration for Graphic Design</StyledP1>
          </StyledCard1>
        </StyledRow>
        <StyledRow1>
          <StyledCard>
            <StyledIcon>
              <StyledIcon1>
                <GrSend style={{ width: "100%", height: "100%" }} />
              </StyledIcon1>
            </StyledIcon>
            <StyledP1>Publication Graphic Design</StyledP1>
          </StyledCard>
          <StyledCard>
            <StyledIcon>
              <StyledIcon1>
                <FaPaintBrush style={{ width: "100%", height: "100%" }} />
              </StyledIcon1>
            </StyledIcon>
            <StyledP1>Art and Illustration for Graphic Design</StyledP1>
          </StyledCard>
        </StyledRow1>
      </StyledServices>
    </StyledMain>
  );
}

export default Services;
