import styled from 'styled-components';

export const StyledInfo1=styled.div`
 position: relative;
  display: flex;
height:50vh;
  background: url('../backg.png') center/cover;
  color: white;
  display: flex;
align-items:center;
  justify-content: center;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(3, 55, 94, 0.95);
    z-index: -1;
  }
`;
export const StyledInfo2=styled.div`

`;
export const StyledHeading=styled.div`
display:flex;
text-align:center;
justify-content: center;

`;
export const MainHeading=styled.h2`
font-size:52px;
width:65%;
font-family: Montserrat;
font-weight: 600;
letter-spacing: -0.6;
line-height:68.38px;

@media only screen and (min-width:1081px) and (max-width:1280px){
font-size:42px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
font-size:32px;
}
@media only screen and (min-width:641px) and (max-width:810px){
font-size:32px;
}
@media only screen and (min-width:320px) and (max-width:640px){
font-size:26px;
line-height:34.19px;
}
`;
export const StyledButton=styled.div`
display:flex;
align-items: center;
flex-direction: row;
justify-content: center;
gap:10px;

`;
export const ButtonFirst=styled.div``;
export const Button1=styled.button`
width:168px;
height:64px;
border-radius: 32px;
color:#ffffff;
background:transparent;
border:1px solid #FFFFFF;
font-size:16px;
font-family:'Montserrat';
font-weight:600;
text-align: center;
&:hover{
background-color: rgba(252, 148, 0, 1);

}
@media only screen and (min-width:1081px) and (max-width:1280px){
    width:119.67px;
    height:45.49;
    font-size:12px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
    width: 89.65px;
    height:34.49px;
    font-size:10px;
}
@media only screen and (min-width:641px) and (max-width:810px){
    width: 89.65px;
    height:34.49px;
    font-size:10px;
}
@media only screen and (min-width:320px) and (max-width:640px){
    width: 89.65px;
    height:34.49px;
    font-size:10px;
}
`;
export const ButtonSecond=styled.div``;
export const Button2=styled.button`
width:168px;
height:64px;
border-radius: 32px;
color:#ffffff;
background:transparent;
border:1px solid #FFFFFF;
font-size:16px;
font-family:'Montserrat';
font-weight:600;
text-align: center;
&:hover{
background-color: rgba(252, 148, 0, 1);

}
@media only screen and (min-width:1081px) and (max-width:1280px){
    width:136.67px;
    height:45.49;
    font-size:12px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
    width: 115.65px;
    height:34.49px;
    font-size:10px;
}
@media only screen and (min-width:641px) and (max-width:810px){
    width: 115px;
    height:34.49px;
    font-size:10px;
}
@media only screen and (min-width:320px) and (max-width:640px){
    width: 115px;
    height:34px;
    font-size:10px;
}
`;