import React, { useState } from "react";
import {
  Checkmark,
  MostPopularTag,
  StyledA,
  StyledB,
  StyledButton,
  StyledButton1,
  StyledCard,
  StyledContent,
  StyledH2,
  StyledList,
  StyledListItem,
  StyledP,
} from "./styles";
import { truncateString } from "../../utils/helperFunctions";

export function PriceCard({ cardData, handleModel, isMostPopular }) {
  const listItems = [
    ...cardData.developmentFeatures,
    ...cardData.graphicDesignFeatures,
  ];
  if (listItems.length > 5) {
    listItems.splice(5);
  }
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <StyledCard onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {isMostPopular && (
        <MostPopularTag isHovered={isHovered} className="most-popular-tag">
          MOST POPULAR
        </MostPopularTag>
      )}
      <StyledContent>
        <StyledH2 isHovered={isHovered}>{cardData.name}</StyledH2>
        <StyledP isHovered={isHovered}>
          {truncateString(cardData.description, 40)}
        </StyledP>
        <StyledList>
          {listItems.map((item) => {
            return (
              <StyledListItem isHovered={isHovered}>
                <Checkmark isHovered={isHovered}>&#10003;</Checkmark>
                {truncateString(item, 20)}
              </StyledListItem>
            );
          })}
        </StyledList>
      </StyledContent>
      <StyledButton1
        isHovered={isHovered}
        onClick={() => handleModel(cardData)}
      >
        Choose plan
      </StyledButton1>
    </StyledCard>
  );
}
