// import styled from "styled-components";

// export const StyledSection1 = styled.div`
//   width: 90%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: flex-start;
//   margin-top: 5%;
//   @media only screen and (min-width: 320px) and (max-width: 639px) {
//     align-items: center;
//   }
// `;
// export const StyledP = styled.p`
//   color: #fc9400;
//   text-align: center;
//   font-family: Montserrat;
//   font-size: 26px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 150%; /* 39px */
//   letter-spacing: -0.91px;
//   margin: 0.2rem;
//   @media only screen and (min-width: 1280px) and (max-width: 1536px) {
//     font-size: 24px;
//     letter-spacing: -0.84px;
//   }
//   @media only screen and (min-width: 1081px) and (max-width: 1279px) {
//     font-size: 20px;
//     letter-spacing: -0.7px;
//   }
//   @media only screen and (min-width: 811px) and (max-width: 1080px) {
//     font-size: 16px;
//     letter-spacing: -0.56px;
//   }
//   @media only screen and (min-width: 640px) and (max-width: 810px) {
//     font-size: 16px;
//     letter-spacing: -0.56px;
//   }
//   @media only screen and (min-width: 320px) and (max-width: 639px) {
//     font-size: 14px;
//     letter-spacing: -0.49px;
//   }
// `;
// export const StyledH3 = styled.h3`
//   color: #03375e;
//   font-family: Montserrat;
//   font-size: 48px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 106%; /* 50.88px */
//   letter-spacing: -1.68px;
//   width: 35%;
//   margin: 0.2rem;
//   @media only screen and (min-width: 1280px) and (max-width: 1536px) {
//     font-size: 42px;
//     letter-spacing: -1.47px;
//   }
//   @media only screen and (min-width: 1081px) and (max-width: 1279px) {
//     font-size: 40px;
//     letter-spacing: -1.4px;
//   }
//   @media only screen and (min-width: 811px) and (max-width: 1080px) {
//     font-size: 32px;
//     letter-spacing: -1.12px;
//   }
//   @media only screen and (min-width: 640px) and (max-width: 810px) {
//     font-size: 32px;
//     letter-spacing: -1.12px;
//   }
//   @media only screen and (min-width: 320px) and (max-width: 639px) {
//     width: 85%;
//     font-size: 26px;
//     letter-spacing: -0.91px;
//     text-align: center;
//   }
// `;
// export const StyledButtonSection = styled.div`
//   width: 90%;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-end;
//   align-items: center;
//   @media only screen and (min-width: 320px) and (max-width: 639px) {
//     justify-content: center;
//   }
// `;
// export const svgString = `
//   <svg xmlns="http://www.w3.org/2000/svg" width="124" height="67" viewBox="0 0 124 67" fill="none">
//   <path d="M57 67L49.5 52H69L57 67Z" fill="#03375E"/>
//   <rect width="124" height="52" rx="26" fill="#03375E"/>
// </svg>
// `;
// export const StyledButton = styled.button`
//   width: 124px;
//   height: 67px;
//   flex-shrink: 0;
//   color: #000;
//   text-align: center;
//   font-family: Poppins;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 20px; /* 125% */
//   &hover: {
//     background: url('data:image/svg+xml;utf8,${encodeURIComponent(svgString)}')
//     color: #fff;
//     text-align: center;
//     font-family: Montserrat;
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 600;
//     line-height: 20px; /* 125% */
//   }
//   @media only screen and (min-width: 1280px) and (max-width: 1536px) {
//     width: 103.898px;
// height: 56.139px;
// font-size: 16px;
// line-height: 20px;
// &hover: {
//   font-size: 16px;
//   line-height: 20px;
// }
//   }
//   @media only screen and (min-width: 1081px) and (max-width: 1279px) {
//     width: 88.328px;
// height: 47.726px;
// font-size: 12px;
//       line-height: 20px;
//     &hover: {
//       font-size: 12px;
//       line-height: 20px;
//     }
//   }
//   @media only screen and (min-width: 811px) and (max-width: 1080px) {
//     width: 66.168px;
// height: 35.752px;
// font-size: 10px;
//     &hover: {
//       font-size: 10px;
//     }
//   }
//   @media only screen and (min-width: 640px) and (max-width: 810px) {
//     width: 66.168px;
// height: 35.752px;
//     font-size: 10px;
//         &hover: {
//           font-size: 10px;
//         }
//   }
//   @media only screen and (min-width: 320px) and (max-width: 639px) {
//     width: 66.168px;
// height: 35.752px;
//     font-size: 10px;
//         &hover: {
//           font-size: 10px;
//         }
//   }
// `;
// export const StyledCardSection = styled.div`
//   width: 90%;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 5%;
//   @media only screen and (min-width: 320px) and (max-width: 639px) {
//     flex-direction: column;
//     justify-content: center;
//   }
// `;
// const commonCardStyles = `
//   width: 30%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   height: 622px;
//   flex-shrink: 0;
//   border-radius: 10px;
//   border: 1px solid rgba(3, 55, 94, 0.47);
//   background: #fff;

//   &:hover {
//     border-radius: 10px;
//     background: #03375e;
//     box-shadow: 4px 19px 32.7px 11px rgba(0, 0, 0, 0.15);
//     color: #fff; // Change color on hover
//   }

//   @media only screen and (min-width: 1280px) and (max-width: 1536px) {
//     height: 522px;
//   }

//   @media only screen and (min-width: 1081px) and (max-width: 1279px) {
//     height: 445px;
//   }

//   @media only screen and (min-width: 811px) and (max-width: 1080px) {
//     height: 334px;
//   }

//   @media only screen and (min-width: 640px) and (max-width: 810px) {
//     width: 45%;
//     height: 362px;
//   }

//   @media only screen and (min-width: 320px) and (max-width: 639px) {
//     width: 80%;
//     height: 362px;
//     margin-top: 3%;
//   }
// `;
// export const StyledCard = styled.div`
//   width: 30%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   //   width: 418px;
//   height: 622px;
//   flex-shrink: 0;
//   border-radius: 10px;
//   border: 1px solid rgba(3, 55, 94, 0.47);
//   background: #fff;
//   &:hover {
//     border-radius: 10px;
//     background: #03375e;
//     box-shadow: 4px 19px 32.7px 11px rgba(0, 0, 0, 0.15);
//   }
//   @media only screen and (min-width: 1280px) and (max-width: 1536px) {
//     height: 522px;
//   }
//   @media only screen and (min-width: 1081px) and (max-width: 1279px) {
//     height: 445px;
//   }
//   @media only screen and (min-width: 811px) and (max-width: 1080px) {
//     height: 334px;
//   }
//   @media only screen and (min-width: 640px) and (max-width: 810px) {
//     width: 45%;
//     height: 362px;
//   }
//   @media only screen and (min-width: 320px) and (max-width: 639px) {
//     width: 80%;
//     height: 362px;
//     margin-top: 3%;
//   }
// `;
// export const StyledCard1 = styled.div`
//   width: 30%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   //   width: 418px;
//   height: 622px;
//   flex-shrink: 0;
//   border-radius: 10px;
//   border: 1px solid rgba(3, 55, 94, 0.47);
//   background: #fff;
//   &:hover {
//     border-radius: 10px;
//     background: #03375e;
//     box-shadow: 4px 19px 32.7px 11px rgba(0, 0, 0, 0.15);
//   }
//   @media only screen and (min-width: 1280px) and (max-width: 1536px) {
//     height: 522px;
//   }
//   @media only screen and (min-width: 1081px) and (max-width: 1279px) {
//     height: 445px;
//   }
//   @media only screen and (min-width: 811px) and (max-width: 1080px) {
//     height: 334px;
//   }
//   @media only screen and (min-width: 640px) and (max-width: 810px) {
//     display: none;
//   }
//   @media only screen and (min-width: 320px) and (max-width: 639px) {
//     width: 80%;
//     height: 362px;
//     margin-top: 3%;
//   }
// `;
// export const StyledH5 = styled.h5`
//   color: #03375e;
//   text-align: center;
//   font-family: Montserrat;
//   font-size: 24px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 106%; /* 25.44px */
//   letter-spacing: -0.84px;
//   margin: 0;
//   ${StyledCard}:hover & {
//     color: #fff;
//   }
//   ${StyledCard1}:hover & {
//     color: #fff;
//   }
//   @media only screen and (min-width: 1280px) and (max-width: 1536px) {
//     font-size: 24px;
//     letter-spacing: -0.84px;
//   }
//   @media only screen and (min-width: 1081px) and (max-width: 1279px) {
//     font-size: 20px;
//     letter-spacing: -0.7px;
//   }
//   @media only screen and (min-width: 811px) and (max-width: 1080px) {
//     font-size: 16px;
//     letter-spacing: -0.56px;
//   }
//   @media only screen and (min-width: 640px) and (max-width: 810px) {
//     font-size: 16px;
//     letter-spacing: -0.56px;
//   }
//   @media only screen and (min-width: 320px) and (max-width: 639px) {
//     font-size: 16px;
//     letter-spacing: -0.56px;
//   }
// `;
// export const StyledP1 = styled.p`
//   color: #03375e;
//   text-align: center;
//   font-family: Montserrat;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 106%; /* 16.96px */
//   letter-spacing: -0.56px;
//   margin: 0;
//   & span {
//     color: #fd9400;
//     font-family: Montserrat;
//     font-size: 36px;
//     font-style: normal;
//     font-weight: 700;
//     line-height: 106%;
//     letter-spacing: -1.26px;
//     margin: 0;
//   }
//   ${StyledCard}:hover & {
//     color: #fff;
//   }
//   ${StyledCard1}:hover & {
//     color: #fff;
//   }
//   @media only screen and (min-width: 1280px) and (max-width: 1536px) {
//     font-size: 16px;
//     letter-spacing: -0.56px;
//     & span {
//       font-size: 36px;
//       letter-spacing: -1.26px;
//     }
//   }
//   @media only screen and (min-width: 1081px) and (max-width: 1279px) {
//     font-size: 16px;
//     letter-spacing: -0.56px;
//     & span {
//       font-size: 36px;
//       letter-spacing: -1.26px;
//     }
//   }
//   @media only screen and (min-width: 811px) and (max-width: 1080px) {
//     font-size: 13px;
//     letter-spacing: -0.455px;
//     & span {
//       font-size: 24px;
//       letter-spacing: -0.84px;
//     }
//   }
//   @media only screen and (min-width: 640px) and (max-width: 810px) {
//     font-size: 13px;
//     letter-spacing: -0.455px;
//     & span {
//       font-size: 24px;
//       letter-spacing: -0.84px;
//     }
//   }
//   @media only screen and (min-width: 320px) and (max-width: 639px) {
//     font-size: 13px;
//     letter-spacing: -0.455px;
//     & span {
//       font-size: 24px;
//       letter-spacing: -0.84px;
//     }
//   }
// `;
// export const StyledUl = styled.ul`
//   width: 80%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: flex-start;
// `;
// export const StyledList = styled.li`
//   color: #03375e;
//   font-family: Montserrat;
//   font-size: 18px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 106%; /* 19.08px */
//   letter-spacing: -0.63px;
// `;
// export const StyledRow = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   gap: 20px;
// `;
// export const StyledRow1 = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   gap: 20px;
//   @media only screen and (min-width: 811px) and (max-width: 1080px) {
//     display: none;
//   }
//   @media only screen and (min-width: 640px) and (max-width: 810px) {
//     display: none;
//   }
//   @media only screen and (min-width: 320px) and (max-width: 639px) {
//     display: none;
//   }
// `;
// export const StyledIcon = styled.div`
//   width: 18px;
//   height: 18px;
//   flex-shrink: 0;
//   background: #d9d9d9;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   @media only screen and (min-width: 1280px) and (max-width: 1536px) {
//     width: 17px;
//     height: 17px;
//   }
//   @media only screen and (min-width: 1081px) and (max-width: 1279px) {
//     width: 14px;
//     height: 14px;
//   }
//   @media only screen and (min-width: 811px) and (max-width: 1080px) {
//     width: 12px;
//     height: 12px;
//   }
//   @media only screen and (min-width: 640px) and (max-width: 810px) {
//     width: 12px;
//     height: 12px;
//   }
//   @media only screen and (min-width: 320px) and (max-width: 639px) {
//     width: 12px;
//     height: 12px;
//   }
// `;
// export const StyledIcon1 = styled.div`
//   width: 8.875px;
//   height: 6.625px;
//   color: #000;
//   @media only screen and (min-width: 1280px) and (max-width: 1536px) {
//     width: 6px;
//     height: 5px;
//   }
//   @media only screen and (min-width: 1081px) and (max-width: 1279px) {
//     width: 5px;
//     height: 4px;
//   }
//   @media only screen and (min-width: 811px) and (max-width: 1080px) {
//     width: 4px;
//     height: 3px;
//   }
//   @media only screen and (min-width: 640px) and (max-width: 810px) {
//     width: 4px;
//     height: 3px;
//   }
//   @media only screen and (min-width: 320px) and (max-width: 639px) {
//     width: 4px;
//     height: 3px;
//   }
// `;
// export const StyledP2 = styled.p`
//   color: rgba(3, 55, 94, 0.45);
//   font-family: Montserrat;
//   font-size: 18px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 106%; /* 19.08px */
//   letter-spacing: -0.63px;
//   ${StyledCard}:hover & {
//     color: rgba(255, 255, 255, 0.69);
//   }
//   ${StyledCard1}:hover & {
//     color: rgba(255, 255, 255, 0.69);
//   }
//   @media only screen and (min-width: 1280px) and (max-width: 1536px) {
//     font-size: 16px;
//     letter-spacing: -0.56px;
//   }
//   @media only screen and (min-width: 1081px) and (max-width: 1279px) {
//     font-size: 14px;
//     letter-spacing: -0.49px;
//   }
//   @media only screen and (min-width: 811px) and (max-width: 1080px) {
//     font-size: 12px;
//     letter-spacing: -0.42px;
//   }
//   @media only screen and (min-width: 640px) and (max-width: 810px) {
//     font-size: 12px;
//     letter-spacing: -0.42px;
//   }
//   @media only screen and (min-width: 320px) and (max-width: 639px) {
//     font-size: 12px;
//     letter-spacing: -0.42px;
//   }
// `;
// export const StyledButton1 = styled.button`
//   width: 80%;
//   height: 58px;
//   flex-shrink: 0;
//   border-radius: 32px;
//   background: #03375e;
//   color: #fff;
//   text-align: center;
//   font-family: Montserrat;
//   font-size: 20px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 20px; /* 100% */
//   border: none;
//   ${StyledCard}:hover & {
//     border-radius: 32px;
//     background: #fc9400;
//   }
//   ${StyledCard1}:hover & {
//     border-radius: 32px;
//     background: #fc9400;
//   }
//   @media only screen and (min-width: 1280px) and (max-width: 1536px) {
//     height: 48px;
//   }
//   @media only screen and (min-width: 1081px) and (max-width: 1279px) {
//     height: 40px;
//     font-size: 16px;
//   }
//   @media only screen and (min-width: 811px) and (max-width: 1080px) {
//     font-size: 12px;
//     height: 30px;
//   }
//   @media only screen and (min-width: 640px) and (max-width: 810px) {
//     font-size: 12px;
//     height: 33px;
//   }
//   @media only screen and (min-width: 320px) and (max-width: 639px) {
//     font-size: 12px;
//     height: 33px;
//   }
// `;
import styled from "styled-components";

export const StyledMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("image4.png"), lightgray 50% / contain no-repeat;
`;

// export const StyledMain = styled.div`
//   width: 100%;
//   height: 800px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   @media only screen and (min-width: 1081px) and (max-width: 1280px) {
//     height: 680px;
//   }
//   @media only screen and (min-width: 8111px) and (max-width: 1080px) {
//     height: 650px;
//   }
//   @media only screen and (min-width: 641px) and (max-width: 810px) {
//     height: 580px;
//   }
//   @media only screen and (min-width: 320px) and (max-width: 640px) {
//     height: 1380px;
//   }
// `;
export const StyledA = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../Price.png");
  background-position: center;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 250px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 210px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 150px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 250px;
  }
`;
export const StyledH1 = styled.h1`
  color: #1b152b;
  text-align: center;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 30px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 20px;
    width: 90%;
  }
`;
export const StyledButtonRow = styled.div`
  width: 60%;
  height: 43px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3%;

  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 38px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 30px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 22px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    flex-wrap: wrap;
    width: 90%;
  }
`;
export const StyledButton = styled.button`
  cursor: pointer;
  width: 171px;
  height: 43px;
  flex-shrink: 0;
  border-radius: 9999px;
  border: 1px solid rgba(0, 0, 0, 0.02);
  background: ${(props) => (props.active ? "#ee991c" : "#fafafa")};
  color: ${(props) => (props.active ? "#000" : "#000")};
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border-radius: 9999px;
    border: 1px solid rgba(238, 153, 28, 0.29);
    background: #ee991c;
    font-weight: 600;
  }

  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 151.518px;
    height: 34.972px;
    font-size: 14px;
  }

  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 120px;
    height: 29px;
    font-size: 12px;
  }

  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 113px;
    height: 28px;
    font-size: 11px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 100px;
    height: 28px;
    font-size: 9px;
  }
`;
export const StyledButton2 = styled.button`
  width: 171px;
  height: 43px;
  flex-shrink: 0;
  border-radius: 9999px;
  border: 1px solid rgba(0, 0, 0, 0.02);
  background: ${(props) => (props.active ? "#ee991c" : "#fafafa")};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border-radius: 9999px;
    border: 1px solid rgba(238, 153, 28, 0.29);
    background: #ee991c;
    font-weight: 600;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 121.518px;
    height: 34.972px;
    font-size: 14px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 103px;
    height: 29px;
    font-size: 12px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 103px;
    height: 28px;
    font-size: 12px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    display: none;
  }
`;
export const StyledB = styled.div`
  width: 85%;
  height: 446px;
  flex-shrink: 0;
  border-radius: 26px;
  background: #f7f8fc;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 440px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 375px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 350px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 310px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    flex-direction: column;
    height: 1147px;
    width: 90%;
  }
`;
export const MostPopularTag = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 5px 160px;
  padding: 5px 10px;
  width: 114.031px;
  height: 25.699px;
  border-radius: 13.5px;
  background: #92dfef;
  border-radius: 13.5px;
  background: #92dfef;
  color: #16205d;
  text-align: center;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.833px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: ${(props) => (props.isHovered ? "flex" : "none")};
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 114px;
    height: 25px;
    margin: 0px 0px 30px 141px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 91px;
    height: 20px;
    font-size: 9px;
    margin: 0px 0px 20px 111px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 111px;
    height: 23px;
    font-size: 9px;
    margin: 0px 0px 10px 100px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 91px;
    height: 17px;
    font-size: 7px;
    margin: 0px 0px 8px 75px;
  }
  @media only screen and (min-width: 411px) and (max-width: 640px) {
    width: 111px;
    height: 23px;
    font-size: 9px;
    margin: 0px 0px 30px 195px;
  }
  @media only screen and (min-width: 351px) and (max-width: 410px) {
    width: 111px;
    height: 23px;
    font-size: 9px;
    margin: 0px 0px 30px 141px;
  }
  @media only screen and (min-width: 320px) and (max-width: 350px) {
    width: 111px;
    height: 23px;
    font-size: 9px;
    margin: 0px 0px 30px 90px;
  }
`;
export const StyledCard = styled.div`
  width: 323.98px;
  height: 446.19px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 1s ease;
  background-image: url("../Pricecard.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top right;
  cursor: pointer;
  &:hover {
    transform: translateY(-5%);
    border-radius: 26px;
    background: #16205d;
    box-shadow: 0px 42px 34px 0px rgba(82, 67, 194, 0.3);
    background-image: url("../Pricecard.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: top right;
    .most-popular-tag {
      opacity: 1;
    }
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 297.98px;
    height: 446.19px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 242.98px;
    height: 355.19px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 242.98px;
    height: 350.19px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 197.98px;
    height: 310px;
    &:hover {
      transform: translateY(-3%);
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 85%;
    height: 380.19px;
    &:hover {
      transform: translateY(-3%);
    }
  }
`;
export const StyledCard1 = styled.div`
  width: 256.98px;
  height: 446.19px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 1s ease;
  &:hover {
    transform: translateY(-20%);
    border-radius: 26px;
    background: #5243c2;
    box-shadow: 0px 42px 34px 0px rgba(82, 67, 194, 0.3);
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 256.98px;
    height: 446.19px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 200.98px;
    height: 355.19px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    display: none;
  }
  @media only screen and (min-width: 320px) and (max-width: 810px) {
    display: none;
  }
`;
export const StyledContent = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 90%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 90%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 90%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 90%;
  }
`;
export const StyledH2 = styled.h2`
  color: ${(props) => (props.isHovered ? "#fff" : " #231d4f")};
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 4%;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 20px;
  }

  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 20px;
  }
`;
export const StyledP = styled.p`
  color: ${(props) => (props.isHovered ? "#fff" : " #848199")};
  width: 90%;
  margin-top: 2%;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 100%;
    font-size: 12px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
  }
`;
export const StyledList = styled.ul`
  height: 200px;
  list-style: none;
  padding: 0;
  margin-top: 2%;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 160px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 180px;
    margin-top: 0%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 150px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 150px;
  }
`;

export const StyledListItem = styled.li`
  //   margin-bottom: 10px;
  display: flex;
  align-items: center;
  margin-top: 9%;
  color: ${(props) => (props.isHovered ? "#fff" : " #848199")};
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 12px;
    margin-top: 7%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
    margin-top: 9%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 12px;
    margin-top: 4%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
    margin-top: 5%;
  }
`;

export const Checkmark = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22.154px;
  height: 21.258px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: rgba(3, 55, 94, 0.1); /* Circle background color */
  color: ${(props) => (props.isHovered ? "#fff" : " rgba(3, 55, 94, 1)")};
  font-size: 1em;
  margin-right: 10px;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 18px;
    height: 18px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
  }
`;
export const StyledButton1 = styled.button`
  cursor: pointer;
  width: 224.292px;
  height: 39.831px;
  flex-shrink: 0;
  border-radius: 24px;
  opacity: 1;
  margin-top: 5%;
  /* background: ; */
  background-color: ${(props) =>
    props.isHovered ? "rgba(238, 153, 28, 1)" : "#93d7d8"};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: ${(props) =>
    props.isHovered ? "rgba(0, 0, 0, 1)" : " rgba(3, 55, 94, 1)"};
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 175px;
    height: 35px;
    font-size: 14px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 190px;
    height: 32px;
    font-size: 14px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 168px;
    height: 30px;
    font-size: 12px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 90%;
    height: 35px;
    font-size: 12px;
  }
`;
