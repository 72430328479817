import styled from "styled-components";

export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  z-index: 1;

`;

export const PopupContent = styled.div`
 background-image: url('../backport.png');
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    height:823px;
  width: 66%;
  transform: translateY(54px);
  @media only screen and (min-width:1081px) and (max-width:1280px){
    width:90%;
    height:771.14px;
    transform: translateY(74px);
  }
  @media only screen and (min-width:811px) and (max-width:1080px){
    width:90%;
    height:583.88px;
    transform: translateY(74px);
  }
  @media only screen and (min-width:641px) and (max-width:810px){
    width:68%;
    height:649px;
    transform: translateY(74px);
  }
  @media only screen and (min-width:320px) and (max-width:640px){
    width:80%;
    height:574px;
    transform: translateY(50px);
  }
`;
export const CBtn=styled.div`
        margin: -2% -2% 0 0;
    float: right;
  width: 44px;
  height: 44px;
  background: #92dfef;
  color:#000000;
  background: #FFFFFF;

 border-radius: 20px;
 cursor: pointer;
 @media only screen and (min-width:320px) and (max-width:640px){
  width:17.69px;
  height:17.69px;
 } 
`;
export const CloseButton = styled.span`
     cursor: pointer;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    margin-top: 22%;
    width: 100%;
    height: 23px;
    @media only screen and (min-width:320px) and (max-width:640px){
      margin-top:-9%;
    }
`;
export const BackImage=styled.div`

`;
export const Image1=styled.div`
  width:88%;
  height:681px;
  margin:6% 6%;
 background:rgba(255,255,255,.35);
 filter: brightness(1);
border-radius: 15px;
@media only screen and (min-width:1081px) and (max-width:1280px){
  height:638.09px;
}
@media only screen and (min-width:811px) and (max-width:1080px){

  height:483.14px;
}

@media only screen and (min-width:641px) and (max-width:810px){
  height:555px;
}
@media only screen and (min-width:320px) and (max-width:640px){
  height:518.92px;
}
`;

export const CenterImage=styled.div`
`;
export const Image2=styled.div`
display: flex;
align-items:center;
justify-content: center;
`;
export const Img3=styled.img`
position: absolute;
top:-10%;
width:480.77px;
height:263px;

@media only screen and (min-width:1081px) and (max-width:1280px){
  width:480.77px;
height:290px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
  width:420.77px;
  height:233.5px;
}

@media only screen and (min-width:641px) and (max-width:810px){
  width:388px;
height:213.42px;
}
@media only screen and (min-width:320px) and (max-width:640px){
  width:270.12px;
height:150.21px;
}
`;
export const Heading1=styled.div`
display:flex;
align-items: center;
justify-content: center;
flex-direction:column;

`;
export const HeadingFirst=styled.h1`
position: absolute;
top:26%;
color: #ee991c;
font-size:32px;
font-family:'Montserrat';
font-weight:700;
span {
  color:#03375E;
}
@media only screen and (min-width:1081px) and (max-width:1280px){


  top:36%;
}
@media only screen and (min-width:811px) and (max-width:1080px){


top:38%;
}
@media only screen and (min-width:641px) and (max-width:810px){


top:28%;
}
@media only screen and (min-width:320px) and (max-width:640px){

  font-size:20px;
top:23%;
text-align:center;
}
`;
export const FormContainer=styled.div`
position:absolute;
top:37%;

@media only screen and (min-width:1081px) and (max-width:1280px){


  top:48%;
}
@media only screen and (min-width:811px) and (max-width:1080px){
  
  top:53%
}
`;
export const FormFields=styled.div``;
export const Img1=styled.img``;
export const FormFields1=styled.div`
display:flex;
align-items: center;
justify-content: center;
flex-direction: row;
gap:10px;
@media only screen and (min-width:320px) and (max-width:810px){
  flex-direction: column;

}
`;
export const FormFields2=styled.div`
display: flex;
align-items: center;
justify-content: center;
margin:10px 0;
`;
export const Form1=styled.div``;
export const Form2=styled.div``;
export const Form3=styled.div``;
export const InputIcon=styled.div`
position: absolute;
margin-left: 2%;
margin-top:2.5%;
@media only screen and (min-width:811px) and (max-width:1080px){
  margin-left: 2%;
margin-top:2%;
}
@media only screen and (min-width:641px) and (max-width:810px){
  margin-left: 2%;
margin-top:4%;
}
@media only screen and (min-width:320px) and (max-width:640px){
  margin-left: 2%;
margin-top:4%;
}
`;
export const InputIcon2=styled.div`
position: absolute;
margin-left: 2%;
margin-top:2.5%;
@media only screen and (min-width:811px) and (max-width:1080px){
  margin-left: 2%;
margin-top:2%;
}
@media only screen and (min-width:641px) and (max-width:810px){
  margin-left: 2%;
  margin-top:4%;
}
@media only screen and (min-width:320px) and (max-width:640px){
  margin-left: 2%;
  margin-top:4%;
}
`;
export const InputIcon1=styled.div`
position: absolute;
margin-left: 2%;
margin-top:3%;
@media only screen and (min-width:811px) and (max-width:1080px){
  margin-left: 2%;
margin-top:4%;
}
@media only screen and (min-width:641px) and (max-width:810px){
  margin-left: 3%;
margin-top:8%;
}
@media only screen and (min-width:320px) and (max-width:640px){
  margin-left: 3%;
margin-top:8%;
}
`;
export const Btn=styled.div`
display: flex;
align-items: center;
justify-content: center;
`;
export const Button1=styled.button`
width:404px;
height:52px;
border-radius:51px;
background: rgba(3, 55, 94, 1);
color:#FFFFFF;
border:none;
font-size:16px;
font-family: 'Montserrat';
font-weight: 600;
cursor:pointer;
@media only screen and (min-width:1081px) and (max-width:1280px){
  width:378.54px;
height:48.72px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
  width:286.62px;
height:36.89px;
}
@media only screen and (min-width:641px) and (max-width:810px){
  width:286.62px;
height:36.89px;
}
@media only screen and (min-width:320px) and (max-width:640px){
  width: 229.73px;
height:39.72px;
}
`;
export const Input=styled.input`
width:300px;
height:52px;
border-radius: 7px;
padding-left: 45px;
border:1px solid rgba(3, 55, 94, 1);
&::placeholder{
font-family: 'Montserrat';
font-size:14px;
font-weight:500;
color:rgba(0, 24, 41, 0.54);


}
@media only screen and (min-width:1081px) and (max-width:1280px){
  width:278px;
  height:48px;

}
@media only screen and (min-width:811px) and (max-width:1080px){
  width:241.21px;
  height:36.89px;
}
@media only screen and (min-width:641px) and (max-width:810px){
  width:241.21px;
  height:36.89px;

}
@media only screen and (min-width:320px) and (max-width:640px){
  width:183px;
  height:36.89px;

}
`;
export const Textarea=styled.textarea`
width:660px;
height:80px;
border-radius: 7px;
border:1px solid rgba(3, 55, 94, 1);
padding-left: 45px;
padding-top: 20px;
&::placeholder{

  font-family: 'Montserrat';
font-size:14px;
font-weight:500;
color:rgba(0, 24, 41, 0.54);

}
@media only screen and (min-width:1081px) and (max-width:1280px){
  width:615px;
  height:80px;

}
@media only screen and (min-width:811px) and (max-width:1080px){
  width:545px;
  height:71.65px;

}
@media only screen and (min-width:641px) and (max-width:810px){
  width:245px;
  height:72px;

}
@media only screen and (min-width:320px) and (max-width:640px){
  width:183px;
  height:71.65px;

}
`;