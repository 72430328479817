import styled,{keyframes} from "styled-components";


const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledMain = styled.div`
  display: flex;
  width: 1000px;
  align-items: center;
  border-radius: 32px;
  justify-content: center;
 @media only screen and (min-width: 1081px) and (max-width: 1280px) {
  width: 850px;
 }
 @media only screen and (min-width: 811px) and (max-width: 1080px) {
  width: 750px;
 }
 @media only screen and (min-width: 641px) and (max-width: 810px) {
  width: 620px;
 }
 @media only screen and (min-width: 460px) and (max-width: 640px) {
 width: 420px;
  }
  @media only screen and (min-width: 375px) and (max-width: 459px) {
 width: 350px;
  }
@media only screen and (min-width: 320px) and (max-width: 475px) {
 width: 305px;
  }
`;
export const Loader=styled.div` 
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: ${spinAnimation} 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@media only screen and (min-width:280px) and (max-width:711px){
  width:25px;
  height:25px;
}
`;
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure modal appears above other content */
  backdrop-filter: blur(17px);
`;

export const ModalContent = styled.div`

`;
export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 80px;
  cursor: pointer;
  color:#ffffff;

`;
export const BufferingLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border: 4px solid transparent;
  border-top-color: #3498db;
  border-radius: 50%;
  animation: ${spinAnimation} 1s linear infinite;
  z-index: 1;
`;
export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  display:flex;
  align-items: center;
  justify-content:center;
`;

export const VideoIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

`;
