import styled from "styled-components";

export const BackgroundImage = styled.div`
  background-image: url('../bg.png');
  width:100%;
background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  
  flex-direction: column;
  height:   100vh; 
   &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(3, 55, 94, .46);
  }
  @media only screen and (min-width:320px) and (max-width:640px){
    
  }
`;
export const Section = styled.section`
  text-align: center;
 display:flex;
  align-items: center;
  justify-content: center;

`;
export const Section1=styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media only screen and (min-width:320px) and (max-width:640px){
    margin-top:11%;
  }
  `;
  export const Heading=styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:80vh;
  `;
export const BigHeading = styled.h1`
  font-size: 100px;
  font-weight:700;
  font-family: 'Montserrat';
  letter-spacing: -4.64px;
  line-height:104.4px;
  color:#FFFFFF;
  filter: brightness(2.2);
  @media only screen and (min-width:1081px) and (max-width:1280px){
  font-size: 90px;
}
  @media only screen and (min-width:811px) and (max-width:1080px){
  font-size: 64px;
  line-height:70.4px;
}
  @media only screen and (min-width:641px) and (max-width:810px){
  font-size: 64px;
  line-height:75.4px;
}
@media only screen and (min-width:320px) and (max-width:640px){
  font-size: 34px;
  letter-spacing: -0.64px;
  line-height:40.4px;
}
`;
export const SmallHeading=styled.h2`
font-size:26px;
font-family: 'Montserrat';
color:#FFFFFF;
font-weight:500;
letter-spacing:-0.5px;
filter: brightness(2.5);
transform: translateY(90px);
@media only screen and (min-width:1081px) and (max-width:1280px){
  letter-spacing:0.1px;
  font-size: 24px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
  font-size: 16px;
  transform: translateY(26px);
}

@media only screen and (min-width:641px) and (max-width:810px){
  font-size: 16px;
  transform: translateY(35px);
}
@media only screen and (min-width:320px) and (max-width:640px){
  font-size: 12px;
  transform: translateY(36px);
  letter-spacing:0.4px;
}
`;

