import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import axios from "axios";
import FullScreenGallery from "../GalleryComp/index";
import {
  OverlayLink,
  StyledButton,
  StyledCard,
  StyledCard1,
  StyledCard2,
  StyledCard3,
  StyledCard4,
  StyledCard5,
  StyledCard6,
  StyledCard7,
  StyledCardSection,
  StyledH2,
  StyledH3,
  StyledIcon,
  StyledIcon1,
  StyledIcon2,
  StyledIconSection,
  StyledInfo,
  StyledMain,
  StyledP,
  StyledP1,
  StyledP2,
  StyledP3,
  StyledP4,
  StyledRow,
  StyledSection1,
  StyledSection2,
  StyledText,
  StyledText1,
} from "./styles";
import { MdArrowRight, MdMail } from "react-icons/md";
import { RiReactjsLine } from "react-icons/ri";
import { FaArrowRight } from "react-icons/fa6";
import { Icon } from "@iconify/react";
import { ProjectDetail1 } from "../Data/portData";

const Portfolio = ({ id }) => {
  const [images, setImages] = useState([]);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const [activeButton, setActiveButton] = useState(0);
  const [counterOn, setCounterOn] = useState(false);

  const handleButtonClick = (index) => {
    setActiveButton(index);
  };

  useEffect(() => {
    fetchImages(0);
  }, []);

  const fetchImages = (id) => {
    axios
      .post(`https://designersapi.liamcrest.com/api/api/get_type_images`, {
        type_id: id,
      })
      .then((res) => {
        if (res.data.data !== undefined && res.data.data.length > 0) {
          setImages(res.data.data);
        } else if (res.data.message === "No, Image Found!") {
          setImages([]);
        } else if (res.data.status === "error") {
          setImages([]);
        }
      });
  };
  const handleOpen = (index) => {
    const id = index + 1;
    setActiveButton(index);
    setSelectedImageIndex(0);
    fetchImages(id);
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setGalleryOpen(false);
  };

  const navigateLeft = () => {
    const newIndex = (selectedImageIndex - 1 + images.length) % images.length;
    setSelectedImageIndex(newIndex);
  };

  const navigateRight = () => {
    const newIndex = (selectedImageIndex + 1) % images.length;
    setSelectedImageIndex(newIndex);
  };

  return (
    <StyledMain id="Work">
      <StyledSection1>
        <StyledText>
          <StyledText1>
            <StyledP>Our WORK</StyledP>
            <StyledH3>Some of Our Portfolio</StyledH3>
          </StyledText1>
          <StyledP1>
            Graphic design is both a profession and an academic discipline
            focused on crafting visual communications that convey targeted
            messages to social groups, aiming to achieve specific objectives
          </StyledP1>
        </StyledText>
        <StyledCardSection>
          <StyledRow>
            <StyledCard onClick={() => handleOpen(0)}>
              <OverlayLink>
                <StyledP3>Visual Identity Graphic Designs</StyledP3>
                <StyledP4>
                  Visual identity is the introduction of your brand. Each part
                  of your design is a clue that tells the audience what to know
                  about your organization.
                </StyledP4>
                <StyledButton>
                  <StyledIcon2>
                    <FaArrowRight
                      style={{ width: "100%", height: "100%" }}
                    ></FaArrowRight>
                  </StyledIcon2>
                </StyledButton>
              </OverlayLink>
            </StyledCard>
            <StyledCard1 onClick={() => handleOpen(1)}>
              <OverlayLink>
                <StyledP3>Marketing & Advertising</StyledP3>
                <StyledP4>
                  Businesses allocate resources for marketing initiatives to
                  shape audience perception. Visual designers crafting ads
                  collaborate with decision-makers and marketing professionals.
                </StyledP4>
                <StyledButton>
                  <StyledIcon2>
                    <FaArrowRight
                      style={{ width: "100%", height: "100%" }}
                    ></FaArrowRight>
                  </StyledIcon2>
                </StyledButton>
              </OverlayLink>
            </StyledCard1>
            <StyledCard2 onClick={() => handleOpen(2)}>
              <OverlayLink>
                <StyledP3>User Interface</StyledP3>
                <StyledP4>
                  Our designers use Adobe Photoshop, Illustrator & XD for UI
                  design— emphasizing style. They aim to create user-friendly
                  interfaces, spanning graphical, website, and voice-controlled
                  forms.
                </StyledP4>
                <StyledButton>
                  <StyledIcon2>
                    <FaArrowRight
                      style={{ width: "100%", height: "100%" }}
                    ></FaArrowRight>
                  </StyledIcon2>
                </StyledButton>
              </OverlayLink>
            </StyledCard2>
          </StyledRow>
          <StyledRow>
            <StyledCard3 onClick={() => handleOpen(3)}>
              <OverlayLink>
                <StyledP3>Publication</StyledP3>
                <StyledP4>
                  Publication designs reach the public via print distribution.
                  This classic design includes books, newspapers, magazines, and
                  catalogs, showcasing timeless elegance and effective
                  communication.
                </StyledP4>
                <StyledButton>
                  <StyledIcon2>
                    <FaArrowRight
                      style={{ width: "100%", height: "100%" }}
                    ></FaArrowRight>
                  </StyledIcon2>
                </StyledButton>
              </OverlayLink>
            </StyledCard3>
            <StyledCard4 onClick={() => handleOpen(4)}>
              <OverlayLink>
                <StyledP3>Packaging</StyledP3>
                <StyledP4>
                  Packaging designs enhance product exteriors with materials,
                  graphics, colors, and fonts. Applies to items like water
                  bottles, boxes, paper, and plastic packaging for effective
                  visual appeal.
                </StyledP4>
                <StyledButton>
                  <StyledIcon2>
                    <FaArrowRight
                      style={{ width: "100%", height: "100%" }}
                    ></FaArrowRight>
                  </StyledIcon2>
                </StyledButton>
              </OverlayLink>
            </StyledCard4>
            <StyledCard5 onClick={() => handleOpen(5)}>
              <OverlayLink>
                <StyledP3>Motion </StyledP3>
                <StyledP4>
                  Motion graphics tell stories or convey messages. Our video
                  marketing blends live content with motion graphics—text,
                  visuals, sound, and motion elements —for simplified, visually
                  appealing concepts
                </StyledP4>
                <StyledButton>
                  <StyledIcon2>
                    <FaArrowRight
                      style={{ width: "100%", height: "100%" }}
                    ></FaArrowRight>
                  </StyledIcon2>
                </StyledButton>
              </OverlayLink>
            </StyledCard5>
          </StyledRow>
          <StyledRow>
            <StyledCard6 onClick={() => handleOpen(6)}>
              <OverlayLink>
                <StyledP3>Environmental</StyledP3>
                <StyledP4>
                  Entering a space demands clarity. Environmental Graphic Design
                  uses elements—colors, fonts, patterns, images, materials,
                  messaging—to tell a story, creating a meaningful user
                  experience.
                </StyledP4>
                <StyledButton>
                  <StyledIcon2>
                    <FaArrowRight
                      style={{ width: "100%", height: "100%" }}
                    ></FaArrowRight>
                  </StyledIcon2>
                </StyledButton>
              </OverlayLink>
            </StyledCard6>
            <StyledCard7 onClick={() => handleOpen(7)}>
              <OverlayLink>
                <StyledP3>Art & Illustration</StyledP3>
                <StyledP4>
                  Graphic design communicates through typography, color, and
                  form. Illustration creatively clarifies, benefiting
                  businesses. Visual ideas are expressed in websites, fabrics,
                  packaging, posters, books, ads, and signs.
                </StyledP4>
                <StyledButton>
                  <StyledIcon2>
                    <FaArrowRight
                      style={{ width: "100%", height: "100%" }}
                    ></FaArrowRight>
                  </StyledIcon2>
                </StyledButton>
              </OverlayLink>
            </StyledCard7>
          </StyledRow>
        </StyledCardSection>

        {galleryOpen && (
          <FullScreenGallery
            images={images}
            selectedImageIndex={selectedImageIndex}
            closeGallery={closeGallery}
            navigateLeft={navigateLeft}
            navigateRight={navigateRight}
          />
        )}
      </StyledSection1>
      <StyledSection2>
        <StyledInfo>
          {ProjectDetail1.map((detail, index) => (
            <StyledIconSection key={index}>
              <StyledIcon>
                <StyledIcon1>{detail.ProjectIcon}</StyledIcon1>
              </StyledIcon>
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                <StyledH2>
                  {counterOn && (
                    <CountUp
                      start={activeButton === index ? 0 : null}
                      end={parseInt(detail.ProjectNumber)}
                      duration={2}
                      delay={0}
                    />
                  )}
                  +
                </StyledH2>
              </ScrollTrigger>
              <StyledP2>{detail.ProjectDetail}</StyledP2>
            </StyledIconSection>
          ))}
        </StyledInfo>
      </StyledSection2>
    </StyledMain>
  );
};

export default Portfolio;
