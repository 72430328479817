import React from 'react';
import { useNavigate } from 'react-router-dom';
import {DetailInfo,DetailDiv,DetailForm,DetailForm1,DetailForm2,DetailForm3,Label,InputField,TextArea,
DetailHeading,DetailH,DetailItem,DetailFormHeading,Btn,Button1,Button,Btn1} from './style';


export const DetailForms = () => {

  const navigate = useNavigate();

  const HandleCancelButton = () => {
    navigate(`/`);
  }



 

  return (
    <DetailInfo>
        <DetailDiv>
          <DetailItem>
           <DetailHeading>
              <DetailH>Detail About Plan</DetailH>
           </DetailHeading>
           <DetailFormHeading>
            <DetailForm>
                <Label  htmlFor="inputField">Name:</Label>
                <InputField
                  id="name"
                placeholder="Name"
    
                
                 />
            </DetailForm>
            <DetailForm1>
                <Label  htmlFor="inputField">Email:</Label>
                <InputField
                   id="email"
                placeholder="Email"
               
                />
            </DetailForm1>
            <DetailForm2>
                <Label  htmlFor="inputField" >Plan Category:</Label>
                <InputField
                   id="plan"
                placeholder="Plan Category"
                
                 readOnly 
                />
            </DetailForm2>
            <DetailForm2>
                <Label  htmlFor="inputField" >Plan Type:</Label>
                <InputField
                   id="plan"
                   placeholder="Plan Type"
               
                  readOnly
                
                />
            </DetailForm2>
           <DetailForm3>
                <Label  htmlFor="inputField">Message:</Label>         
              <TextArea
                id="message"
                placeholder="Your Message"
               
                />
            </DetailForm3>
            </DetailFormHeading>
            
        
   
            <Btn>
                <Btn1><Button onClick={HandleCancelButton}>Cancel</Button></Btn1>
              <Button1>Send Request</Button1>
            </Btn>
        
            </DetailItem>
        </DetailDiv>
    </DetailInfo>
  )
}

export default DetailForms