import React from "react";
import {
  BackgroundImage,
  Section,
  Section1,
  BigHeading,
  SmallHeading,
  Heading,
} from "./style";
import Header from "../Header";

const index = () => {
  return (
    <>
      <BackgroundImage id="Home">
        <Header />
        <Heading>
          <Section1>
            <SmallHeading>We appreciate your trust greatly!</SmallHeading>
          </Section1>

          <Section>
            <BigHeading>Pinnacle of Design Excellence</BigHeading>
          </Section>
        </Heading>
      </BackgroundImage>
    </>
  );
};

export default index;
