import React from "react";
import Section1 from "../../Section1";
import Section4 from "../../Section4";
import Section6 from "../../section6";
import Section7 from "../../Section7";

import Footer from "../../Footer";
import Services from "../../Services";
import Portfolio from "../../Portfolio";
import Pricing from "../../Pricing";
const Home = () => {
  return (
    <>
      <Section1 />
      <Services></Services>
      <Section4 />
      <Portfolio></Portfolio>
      <Pricing></Pricing>
      <Section6 />
      <Section7 />
      <Footer />
    </>
  );
};

export default Home;
