export const basicWebsitePlan = [
  {
    name: "Basic Plan",
    description:
      "A simple and functional multi-page responsive website, suitable for individuals or small businesses",
    pages: 3,
    domainSetup: "Included",
    contactForm: "Included",
    seo: "Included",
    graphicDesignFeatures: ["Logo Design", "Custom Color Scheme"],
    developmentFeatures: [
      "Mobile-Friendly Design",
      "Basic Content Management System (CMS) Integration",
      "Social Media Icons Integration",
      "Image Gallery Setup",
    ],
    days: "10-14 days",
    employees: "2 (1 Graphic Designer, 1 Web Developer)",
  },
  {
    name: "Standard Plan",
    description:
      "A more comprehensive multi-page website with advanced features and functionalities",
    pages: 5,
    domainSetup: "Included",
    contactForm: "Included",
    seo: "Included",
    graphicDesignFeatures: [
      "Logo Design",
      "Custom Color Scheme",
      "Graphics for Banners/Headers",
    ],
    developmentFeatures: [
      "Mobile-Friendly Design",
      "Basic Content Management System (CMS) Integration",
      "Social Media Icons Integration",
      "Image Gallery Setup",
      "Blog Integration",
    ],
    days: "14-20 days",
    employees: "3 (1 Graphic Designer, 1 Web Developer)",
  },
  {
    name: "Premium Plan",
    description:
      "A fully customized and professionally designed website with enhanced features.",
    pages: 7,
    domainSetup: "Included",
    contactForm: "Included",
    seo: "Included",
    graphicDesignFeatures: [
      "Logo Design",
      "Custom Color Scheme",
      "Graphics for Banners/Headers",
      "Iconography/Custom Illustrations",
    ],
    developmentFeatures: [
      "Mobile-Friendly Design",
      "Basic Content Management System (CMS) Integration",
      "Social Media Icons Integration",
      "Image Gallery Setup",
      "Blog Integration",
      "E-commerce Functionality (Up to 10 Products)",
    ],
    days: "21-30 days",
    employees: "4 (2 Graphic Designer, 2 Web Developer)",
  },
];
export const ecommerceStarterPack = [
  {
    name: "Basic Plan",
    description: " basic online store setup for small businesses or startups",
    onlineStoreSetup: "Included",
    cartIntegration: "Included",
    paymentGatewaySetup: "Included",
    certificateInstallation: "Included",
    graphicDesignFeatures: [
      "Basic Store Logo Design",
      "Custom Color Scheme to Match Branding",
    ],
    developmentFeatures: [
      "Mobile-Friendly Design",
      "Product Catalog Setup (Up to 50 Products)",
      "Basic Product Search Functionality",
      "Customer Account Registration",
    ],
    days: "14-21 days",
    employees: "2 (1 Graphic Designer, 1 Web Developer)",
  },
  {
    name: "Standard Plan",
    description: "A basic online store setup for small businesses or startups",
    onlineStoreSetup: "Included",
    cartIntegration: "Included",
    paymentGatewaySetup: "Included",
    certificateInstallation: "Included",
    graphicDesignFeatures: [
      "Custom Store Logo Design",
      "Custom Color Scheme to Match Branding",
      "Graphics for Banners/Headers",
    ],
    developmentFeatures: [
      "Mobile-Friendly Design",
      "Product Catalog Setup (Up to 100 Products)",
      "Advance Product Search and Filtering",
      "Customer Account Registration and Management",
      "Product Reviews and Ratings",
    ],
    days: "21-30 days",
    employees: "3 (1 Graphic Designer, 2 Web Developer)",
  },
  {
    name: "Premium Plan",
    description:
      "A feature-rich and scalable e-commerce solution for established online retailers",
    onlineStoreSetup: "Included",
    cartIntegration: "Included",
    paymentGatewaySetup: "Included",
    certificateInstallation: "Included",
    graphicDesignFeatures: [
      "Custom Store Logo Design",
      "Custom Color Scheme to Match Branding",
      "Graphics for Banners/Headers",
      "Iconography/Custom Illustrations",
    ],
    developmentFeatures: [
      "Mobile-Friendly Design",
      "Product Catalog Setup (Up to 100 Products)",
      "Advance Product Search and Filtering",
      "Customer Account Registration and Management",
      "Product Reviews and Ratings",
      "Wishlist and Comparison Features",
      "Advanced Sales and Inventory Management",
    ],
    days: "30-45 days",
    employees: "4 (2 Graphic Designer, 3 Web Developer)",
  },
];
export const portfolioShowcase = [
  {
    name: "Basic Plan",
    description:
      "An interactive portfolio website designed to showcase a creative professional's work and talent, suitable for freelancers or beginners.",
    portfolioDesign: "Included",
    galleryIntegration: "Included",
    resumePageCreation: "Included",
    socialMediaIntegration: "Included",
    graphicDesignFeatures: ["Custom Color Scheme to Match Branding"],
    developmentFeatures: [
      "Mobile-Friendly Design",
      "Basic Menu and Navigation Setup",
      "Contact Form Integration",
    ],
    days: "10-14 days",
    employees: "2 (1 Graphic Designer, 1 Web Developer)",
  },
  {
    name: "Standard Plan",
    description:
      "A more elaborate portfolio showcase with advanced interactivity and navigation features, suitable for established professionals or small agencies.",
    portfolioDesign: "Included",
    galleryIntegration: "Included",
    resumePageCreation: "Included",
    socialMediaIntegration: "Included",
    graphicDesignFeatures: [
      "Custom Color Scheme to Match Branding",
      "Graphics for Banners/Headers",
    ],
    developmentFeatures: [
      "Mobile-Friendly Design",
      "Advance Menu and Navigation Setup",
      "Contact Form Integration",
      "Blog Integration",
    ],
    days: "14-21 days",
    employees: "2 (1 Graphic Designer, 1 Web Developer)",
  },
  {
    name: "Premium Plan",
    description:
      "A fully immersive and personalized portfolio showcase with multimedia integration, perfect for high-profile artists or design studios",
    portfolioDesign: "Included",
    galleryIntegration: "Included",
    resumePageCreation: "Included",
    socialMediaIntegration: "Included",
    graphicDesignFeatures: [
      "Custom Color Scheme to Match Branding",
      "Graphics for Banners/Headers",
      "Iconography/Custom Illustrations",
    ],
    developmentFeatures: [
      "Mobile-Friendly Design",
      "Advance Menu and Navigation Setup",
      "Contact Form Integration",
      "Blog Integration",
      "Basic SEO Setup",
      "Multimedia Integration (Video/Audio)",
    ],
    days: "21-30 days",
    employees: "3 (2 Graphic Designer, 2 Web Developer)",
  },
];
export const blogSetupPackage = [
  {
    name: "Basic Plan",
    description:
      "Installation of a user-friendly blog platform with basic customization, suitable for personal bloggers or hobbyists.",
    blogPlatformInstallation: "Included (WordPress or other) ",
    themeCustomization: "Basic",
    blogPosts: " Up to 10 posts",
    graphicDesignFeatures: ["Basic Color Scheme Customization"],
    developmentFeatures: [
      "Mobile-Friendly Design",
      "Basic SEO Setup",
      "Social Media Sharing Integration",
    ],
    days: "7-10 days",
    employees: "2 (1 Graphic Designer, 1 Web Developer)",
  },
  {
    name: "Standard Plan",
    description:
      "A more personalized blog setup with advanced theme customization and formatting options.",
    blogPlatformInstallation: "Included (WordPress or other) ",
    themeCustomization: "Advance",
    blogPosts: " Up to 20 posts",
    graphicDesignFeatures: [
      "Basic Color Scheme Customization",
      "Graphics for Banners/Headers",
    ],
    developmentFeatures: [
      "Mobile-Friendly Design",
      "Basic SEO Setup",
      "Social Media Sharing Integration",
      "Comment System Integration",
    ],
    days: "10-14 days",
    employees: "2 (1 Graphic Designer, 1 Web Developer)",
  },
  {
    name: "Premium Plan",
    description:
      "A fully customized blog platform with personalized design elements, advanced SEO, and integrations for content-heavy websites or media companies.",
    blogPlatformInstallation: "Included (WordPress or other) ",
    themeCustomization: "Fully Customized",
    blogPosts: " Up to 30 posts",
    graphicDesignFeatures: [
      "Basic Color Scheme Customization",
      "Graphics for Banners/Headers",
      "Iconography/Custom Illustrations",
    ],
    developmentFeatures: [
      "Mobile-Friendly Design",
      "Basic SEO Setup",
      "Social Media Sharing Integration",
      "Comment System Integration",
      "Blog Category Organization",
      "Subscription/Newsletter Integration",
    ],
    days: "14-21 days",
    employees: "3 (2 Graphic Designer, 2 Web Developer)",
  },
];
export const smallBusiness = [
  {
    name: "Basic Plan",
    description:
      "A basic branding package for startups, including logo design, brand colors, and a simple website with essential pages.",
    logoDesign: "Included",
    brandingElements: "Color, Fonts",
    basicWebsite: "Home, About, Contact pages",
    cardTemplateDesign: "Included",
    graphicDesignFeatures: ["Custom Color Scheme"],
    developmentFeatures: ["Mobile-Friendly Design", "Contact Form Integration"],
    days: "10-14 days",
    employees: "2 (1 Graphic Designer, 1 Web Developer)",
  },
  {
    name: "Standard Plan",
    description:
      "A more comprehensive startup bundle with additional logo design options, typography, and a website with more pages and advanced design features.",
    logoDesign: "Included",
    brandingElements: "Color, Fonts, Typography",
    basicWebsite: "Home, About, Services, Contact pages",
    cardTemplateDesign: "Included",
    graphicDesignFeatures: [
      "Custom Color Scheme",
      "Graphics for Banners/Headers",
    ],
    developmentFeatures: [
      "Mobile-Friendly Design",
      "Contact Form Integration",
      "Image Gallery Setup",
    ],
    days: "14-21 days",
    employees: "2 (1 Graphic Designer, 2 Web Developer)",
  },
  {
    name: "Premium Plan",
    description:
      "A fully customized branding package for startups, including multiple logo design options, extensive branding guidelines, and a feature-rich website with personalized elements",
    logoDesign: "Included (Multiple Options)",
    brandingElements: "Color, Fonts, Typography, Visual Guidelines",
    basicWebsite: "Home, About, Services, Gallery, Contact pages",
    cardTemplateDesign: "Included",
    graphicDesignFeatures: [
      "Custom Color Scheme",
      "Graphics for Banners/Headers",
      "Iconography/Custom Illustrations",
    ],
    developmentFeatures: [
      "Mobile-Friendly Design",
      "Contact Form Integration",
      "Image Gallery Setup",
      "Blog Integration (Optional)",
    ],
    days: "21-30 days",
    employees: "3 (2 Graphic Designer, 3 Web Developer)",
  },
];
