import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const VideoContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  
`;

const VideoWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%; 
  overflow: hidden;
  background: transparent;
  border-radius: 10px;
`;

const VideoIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const VideoTestimonial = ({ videoSrc, autoplay }) => {
  const [src, setSrc] = useState('');
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && autoplay) {
          setSrc(`${videoSrc}?autoplay=1`);
        } else if (entry.isIntersecting) {
          setSrc(videoSrc);
        }
      });
    }, { threshold: 0.5 });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [videoSrc, autoplay]);

  return (
    <VideoContainer ref={videoRef}>
      <VideoWrapper>
        <VideoIframe
          src={src}
          allow="autoplay; fullscreen"
        />
      </VideoWrapper>
    </VideoContainer>
  );
};

export default VideoTestimonial;