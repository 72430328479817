import { RiReactjsLine } from "react-icons/ri";
import { FaRankingStar } from "react-icons/fa6";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { TbWorld } from "react-icons/tb";
export const ProjectDetail1 = [
  {
    ProjectDetail: "Team Members",
    ProjectNumber: "20+",
    ProjectIcon: <RiReactjsLine style={{ width: "100%", height: "100%" }} />,
  },
  {
    ProjectDetail: "Projects Completed",
    ProjectNumber: "160+",
    ProjectIcon: <FaRankingStar style={{ width: "100%", height: "100%" }} />,
  },

  {
    ProjectDetail: "Years Experience",
    ProjectNumber: "8+",
    ProjectIcon: (
      <MdOutlineBusinessCenter style={{ width: "100%", height: "100%" }} />
    ),
  },

  {
    ProjectDetail: "Worldwide Clients",
    ProjectNumber: "50+",
    ProjectIcon: <TbWorld style={{ width: "100%", height: "100%" }} />,
  },
];
