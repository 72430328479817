import React from 'react';
import VideoTestimonial from './VideoTestimonial';
import {StyledInfo, MainHeading,Header,Title} from './style';
const Testimonail = () => {
  const videoSrc = "./Testimonial.mp4";

  return (
    <StyledInfo>
      <MainHeading>      
        <Header>Testimonials</Header>
          <Title>They Trusted Us</Title>
        </MainHeading>
      <VideoTestimonial videoSrc={videoSrc} autoplay={true}/>
    </StyledInfo>
  );
};

export default Testimonail;