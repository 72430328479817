import styled from "styled-components";

export const StyledInfo1=styled.div`

height:6vh;
`;
export const Header = styled.header`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  @media screen and (max-width: 810px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: ${({ click }) => (click ? 0 : '-100%')};
    opacity: 0.9;
    transition: all 0.5s ease;
    background: #101522;
    z-index:1;
    padding:0;
  }
`;
export const MobileIcon=styled.div`
 display: none;

@media screen and (max-width: 810px) {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%, 60%);
  font-size: 1.8rem;
  cursor: pointer;
  filter: brightness(1.2);
  z-index: 0;
}

`;
export const Navigation = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 45%;
  @media only screen and (min-width:811px) and (max-width:1080px){
    width:51%
  }
  @media only screen and (min-width:320px) and (max-width:810px){
    flex-direction: column;
    gap:35px;
  }
`;
export const Logo1=styled.div`
display:none;
@media only screen and (min-width:641px) and (max-width:810px){
  display:flex;
align-items:center;
justify-content: center;
padding:10px;
}
@media only screen and (min-width:200px) and (max-width:640px){
  display:flex;
align-items:center;
justify-content: center;
padding:20px;
}
`;
export const NavLogo=styled.img`
display:none;
@media only screen and (min-width:641px) and (max-width:810px){
display:block;

width:91px;
height:91px;
filter: brightness(1.2);
}
@media only screen and (min-width:320px) and (max-width:640px){
display:block;

width:70px;
height:70px;
filter: brightness(1.2);
}
`; 
export const Logo = styled.img`
  width: 131px; 
height:131px;
filter: brightness(1.2);
@media only screen and (min-width:1081px) and (max-width:1280px){
   width:104.37px;
   height: 104.31px;
   }
   @media only screen and (min-width:811px) and (max-width:1080px){
    width:90.69px;
   height:90.67px;
  }
  @media only screen and (min-width:111px) and (max-width:810px){
    display:none;
  }
`;

export const Link = styled.a`
  position: relative;
  color: #FFFFFF;
  text-decoration: none;
    font-size:18px;
    font-family: 'Montserrat';
    font-weight:500;
    filter: brightness(1.2);
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 2px;
    background-color: #FD9400;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: left;
  }
  @media only screen and (min-width:1081px) and (max-width:1280px){
    font-size:14px;
  }
  @media only screen and (min-width:811px) and (max-width:1080px){
    font-size:16px;
  }

`;
