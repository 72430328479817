import styled from 'styled-components';

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width:95%;
  height:158px;
  background:#95E0EF;
  margin:-7% 2% ; 
  border-radius: 10px;
  position: relative;
    top: -24%;
    z-index: 0;
    @media only screen and (min-width:641px) and (max-width:810px){
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: -20%;
  }
    @media only screen and (min-width:320px) and (max-width:640px){
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: -15%;
  }
  `;
export const Heading1=styled.div`
display: flex;
margin-left:-10%;

flex-direction: column;


@media only screen and (min-width:641px) and (max-width:810px){
  margin-left:-2%;
}

`;
export const Headfirst=styled.div`

`;
export const HeadSecond=styled.div``;
export const Heading = styled.h2`
font-family:'Montserrat';
font-weight:600;
color: #03375E;
width:100%;
font-size:40px;
text-align: left;
line-height:0px;
@media only screen and (min-width:1081px) and (max-width:1280px){
    font-size:32px;
  }    
  @media only screen and (min-width:811px) and (max-width:1080px){
    font-size:24px;
  }
  @media only screen and (min-width:641px) and (max-width:810px){
    font-size:24px;
  }
  @media only screen and (min-width:320px) and (max-width:640px){
    font-size:20px;
  }
`;
export const Heading2 = styled.h2`
width:100%;
font-family:'Montserrat';
font-weight:600;
color: #03375E;
  font-size:36px;
  text-align: left;
  line-height: 0px;
  @media only screen and (min-width:1081px) and (max-width:1280px){
    font-size:32px;
  }
  @media only screen and (min-width:811px) and (max-width:1080px){
    font-size:24px;
  }
  @media only screen and (min-width:641px) and (max-width:810px){
    font-size:24px;
  }
  @media only screen and (min-width:320px) and (max-width:640px){
    font-size:20px;
    text-align: center;
  }
`;
export const InputFieldWrapper = styled.div`
  position: relative;
  @media only screen and (min-width:641px) and (max-width:810px){
    left:-9%;
  }
`;

export const InputField = styled.input`
    height: 80px;
    margin-right: -31px;
    display: flex;
    width: 608px;
    align-items: center;
    gap: 16px;
    margin-left: 21%;
    border-radius: 40px;
    color:#000000;
    border:none;
    padding-left:25px;
    &::placeholder{
        font-size:16px;
        line-height:20px;
        font-family: 'Montserrat';
        font-weight:500;
        margin-left:5%;
        color:#000000;
    }

    @media only screen and (min-width:1081px) and (max-width:1280px){
        width: 432px;
        height:56px;
        &::placeholder{
        font-size:15px;
       
        
    }
    }
    @media only screen and (min-width:811px) and (max-width:1080px){
        width: 323px;
        height:42px;
        &::placeholder{
        font-size:12px;
       
        
    }
    }
    @media only screen and (min-width:641px) and (max-width:810px){
        width: 323px;
        height:42px;
        &::placeholder{
        font-size:12px;
       
        
    }
    }
    @media only screen and (min-width:320px) and (max-width:640px){
        width: 244px;
        height:42px;
        margin-left: -12%;
        &::placeholder{
        font-size:12px;
       
        
    }
    }
`;

export const Button = styled.button`
  position: absolute;
  right: -24%;
  top: 50%;
  transform: translateY(-50%);
  width:137px;
  height:60px;
  border-radius:30px;
  font-family: 'Montserrat';
  font-weight:500;
  font-size:16px;
  color:#FFFFFF;
  line-height: 20px;
  align-items: center;
  background: #03375E;
  border:none;
  cursor:pointer;
  &:hover{
    background:#fc9400;
  transition: 0.9s;
  }
  @media only screen and (min-width:1081px) and (max-width:1280px){
        width:105px;
        height:43px;
        font-size:16px;
    }
    @media only screen and (min-width:811px) and (max-width:1080px){
        width: 79px;
        height:33px;
        font-size:14px;
        right: -28%;
    }
    @media only screen and (min-width:641px) and (max-width:810px){
        width: 79px;
        height:33px;
        font-size:12px;
        right: -28%;
    }
    @media only screen and (min-width:320px) and (max-width:640px){
        width: 87px;
        height:33px;
        font-size:12px;
        right: 2%;
    }
`;