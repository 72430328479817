import styled from 'styled-components';
import  Xarrow  from 'react-xarrows';


export const StyledXarrow = styled(Xarrow)`
 

`;

export const StyledInfo3 = styled.div`
 position: relative;
  display: flex;
  height: 100vh;
  background: url('../bk.png') center/cover;
  color: white;
  display: flex;

  justify-content: center;
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(3, 55, 94, 0.99) 0%, rgba(30, 57, 77, 0.99) 100%);
    z-index: -1;
  }
  @media only screen and (min-width:320px) and (max-width:460px){
    height:100vh;
  }
`;
export const StyledInfo12=styled.div`
  text-align: center;
 justify-content: center;
display:flex;
align-items: center;
flex-direction: column;
`;
export const HeadingTop=styled.div`
  text-align: center;
 align-items: center;
 justify-content: center;
margin-top:-8%;
@media only screen and (min-width:320px) and (max-width:640px){
  margin-top:2%;
}
`;
export const Headingfirst=styled.h2`
font-size:26px;
color:#FC9400;
text-align: center;
font-family: 'Montserrat';
font-weight: 600;
letter-spacing: -0.5px;
text-transform:uppercase;
@media only screen and (min-width:320px) and (max-width:640px){
  font-size: 14px;
}
`;
export const HeadingSecond=styled.h1`
font-size:48px;
color:#FFFFFF;
text-align: center;
font-family: 'Montserrat';
font-weight: 600;
letter-spacing: -0.64px;
transform: translateY(-35px);
@media only screen and (min-width:374px) and (max-width:640px){
  font-size:26px;
  transform: translateY(0px);
  line-height:27.3px;
}
@media only screen and (min-width:320px) and (max-width:373px){
  font-size:26px;
  transform: translateY(-13px);
  line-height:27.3px;
}
`;
export const Hexagon1 =styled.div`

`;

export const Hexagon = styled.div`
position: relative;
width: 188px;
height: 188px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
${(props) => props.downward && 'transform: translateY(50%);'}
clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
margin: 0 25px;
background:rgba(255,255,255,0.1);
&:hover{
  background:rgba(252, 148, 0, 0.73);
}
&::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: url('../poly.png') no-repeat center center/contain; */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

@media only screen and (min-width:1081px) and (max-width:1280px){
  width: 150px;
height: 150px;
}
@media only screen and (min-width: 811px) and (max-width:1080px) {
    width: 111px;
    height: 112px;
    margin: 0 18px;
  }
  @media only screen and (min-width: 641px) and (max-width:810px) {
    width: 87px;
    height: 88px;
    margin: 0 18px;
  }
  @media only screen and (min-width: 389px) and (max-width:640px) {
    width: 87px;
    height: 88px;
    margin: 21px 0px;
    transform: translateY(-20%);
    ${(props) => props.downward && 'transform: translateY(-20%);'}
  }
  /* @media only screen and (min-width: 374px) and (max-width:375px) {
    width: 87px;
    height: 88px;
    margin: 14px 0px;
    transform: translateY(-20%);
    ${(props) => props.downward && 'transform: translateY(-20%);'} */
  /* } */
  @media only screen and (min-width: 320px) and (max-width:388px) {
    width: 87px;
    height: 88px;
    margin: 15px 0px;
    transform: translateY(-20%);
    ${(props) => props.downward && 'transform: translateY(-20%);'}
  }
`;
export const ServiceImage = styled.img`
  width: 87px;
  height: 87px;

  @media only screen and (min-width: 1081px) and (max-width:1280px) {
    width: 62px;
    height: 63px;
  }
  @media only screen and (min-width: 811px) and (max-width:1080px) {
    width: 45px;
    height: 47px;
  }
  @media only screen and (min-width: 641px) and (max-width:810px) {
    width: 35px;
    height: 37px;
  }
  @media only screen and (min-width: 320px) and (max-width:640px) {
    width: 35px;
    height: 37px;
  }
`;
export const ProcessContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
 margin:-8% 5% 0 5%;
 min-height:22vh;
 @media only screen and (min-width:320px) and (max-width:640px){
  flex-direction: column;
  margin:0;
 }
`;

export const StepWrapper = styled.div`
 display : flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
 @media only screen and (min-width:320px) and ( max-width:640px){
  flex-direction:row;
 }
`;
export const StepWrapper1 = styled.div`
 display : flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
 margin-top: 5%;
 @media only screen and (min-width:320px) and ( max-width:640px){
  flex-direction:row;
  margin-top:0;
 }
`;

export const Logo1 = styled.div`
display: flex;
align-items: center;
justify-content: center;

`;
export const Style=styled.div`
align-items:center;
@media only screen and (min-width:389px) and (max-width:640px){
  display:flex;
  flex-direction: column;
  justify-content: left;
  text-align: left !important;
  align-items:last baseline;
  align-self: flex-end;
  transform: translateY(-61%);
  margin-left:15px;
}
@media only screen and (min-width:320px) and (max-width:388px){
  display:flex;
  flex-direction: column;
  justify-content: left;
  text-align: left !important;
  align-items:last baseline;
  align-self: flex-end;
  transform: translateY(-50%);
  margin-left:15px;
}
`;
export const Style1=styled.div`
align-items:center;
transform: translateY(58%);
@media only screen and (min-width:389px) and (max-width:640px){
  display:flex;
  flex-direction: column;
  align-items:last baseline;
  justify-content: left;
  text-align: left !important;
   align-self: flex-end;
   transform: translateY(-68%);
   margin-left:15px;
}
@media only screen and (min-width:320px) and (max-width:388px){
  display:flex;
  flex-direction: column;
  align-items:last baseline;
  justify-content: left;
  text-align: left !important;
   align-self: flex-end;
   transform: translateY(-60%);
   margin-left:15px;
}


`;
export const Style2=styled.div`
align-items:center;
transform: translateY(46%);
@media only screen and (min-width:320px) and (max-width:640px){
  display:flex;
  flex-direction: column;
  align-items:last baseline;
  justify-content: left;
  text-align: left !important;
   align-self: flex-end;
   transform: translateY(-65%);
   margin-left:15px;
}
`;

export const Style3=styled.div`
align-items:center;
@media only screen and (min-width:389px) and (max-width:640px){
  display:flex;
  flex-direction: column;
  justify-content: left;
  text-align: left !important;
  align-items:last baseline;
  align-self: flex-end;
  transform: translateY(-78%);
  margin-left:15px;
}
@media only screen and (min-width:320px) and (max-width:388px){
  display:flex;
  flex-direction: column;
  justify-content: left;
  text-align: left !important;
  align-items:last baseline;
  align-self: flex-end;
  transform: translateY(-78%);
  margin-left:15px;
}
`;
export const Heading1=styled.h2`
font-size: 28px;
line-height:29.68px;
font-family: 'Montserrat';
font-weight: 600;
letter-spacing: -0.5px;
@media only screen and (min-width:1081px) and (max-width:1280px){
font-size: 20px;
line-height:21.68px;

}
@media only screen and (min-width:811px) and (max-width:1080px){
font-size: 15px;
line-height:15.9px;

}
@media only screen and (min-width:641px) and (max-width:810px){
font-size: 15px;
line-height:15.9px;

}
@media only screen and (min-width:320px) and (max-width:640px){
font-size: 15px;
line-height:15.9px;

}
`;
export const Para1=styled.p`
font-size: 14px;
font-family: 'Montserrat';
font-weight: 500;
line-height: 18.2px;
text-align: center;
color:#FFFFFF;
margin-top:-7%;
width:213px;
@media only screen and (min-width:1081px) and (max-width:1280px){
font-size: 12px;
line-height:15.68px;

}
@media only screen and (min-width:811px) and (max-width:1080px){
font-size: 10px;
line-height:13px;
width:100%;
}
@media only screen and (min-width:641px) and (max-width:810px){
font-size: 9px;
line-height:11.9px;
width:100%;
}
@media only screen and (min-width:320px) and (max-width:640px){
  font-size: 9px;
line-height:11.9px;
display:flex;
  flex-direction: column;
  justify-content: left;
  text-align: left !important;
  align-items:last baseline;
  transform: translateY(6px);
}
`;
