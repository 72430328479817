import { useEffect } from "react";
import { createPortal } from "react-dom";
import React from "react";
function Modal({ children, isOpen }) {
  useEffect(() => {
    const body = document.body;
    if (isOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }

    return () => {
      body.style.overflow = "auto";
    };
  }, [isOpen]);

  return isOpen
    ? createPortal(
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "auto",
            zIndex: "10",
          }}
        >
          {children}
        </div>,
        document.body
      )
    : null;
}

export default Modal;
