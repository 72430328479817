import React, { useEffect, useState } from 'react';
import { ProcessContainer, StepWrapper, StepWrapper1,Hexagon,Hexagon1, StyledInfo3,Logo1,Heading1,Para1,Style,Style1,
StyledInfo12,HeadingTop,Headingfirst,HeadingSecond,ServiceImage,StyledXarrow,Style2,Style3} from './style';



const Service1 = () => <ServiceImage src={'../stp1.png'} alt="Mobile Icon"  />;
const Service2 = () => <ServiceImage src={'../stp2.png'} alt="Mobile Icon"  />;
const Service3 = () => <ServiceImage src={'../stp3.png'} alt="Data Analysis"  />;
const Service4 = () => <ServiceImage src={'../stp4.png'} alt="Mobile Icon"  />;
const Service5 = () => <ServiceImage src={'../stp5.png'} alt="Mobile Icon"  />;


  

const StepsProcess = () => {

 
  const [hoveredStep, setHoveredStep] = useState(null);

  const handleHexagonHover = (stepNumber) => {
    setHoveredStep(stepNumber);
  };
  const [, setForceUpdate] = useState();

  useEffect(() => {
    const handleResize = () => setForceUpdate(Math.random());

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <StyledInfo3 id="process">
    <StyledInfo12>
    <HeadingTop>
        <Headingfirst>Our process</Headingfirst>
        <HeadingSecond>Steps for our Work</HeadingSecond>
    </HeadingTop>
      <ProcessContainer >

        <StepWrapper>
        
          <Hexagon id="hexagon1"  onMouseEnter={() => handleHexagonHover(1)}
              onMouseLeave={() => handleHexagonHover(null)}>
          <Logo1>   <Service1/>  </Logo1>
          </Hexagon>
      <StyledXarrow  start="hexagon1" end="hexagon2"    lineColor={hoveredStep === 1 ? '#FC9400' : 'white'}
              headColor={hoveredStep === 1 ? '#FC9400' : 'white'} curveness={0.9} dashness={{ strokeLen: 10, nonStrokeLen: 5 }} strokeWidth={2} />
          <Style> <Heading1 style={{ color: hoveredStep === 1 ? '#FC9400' : 'white' }}>Discover</Heading1>
      <Para1>We commence with the discovery phase, dedicating ample time to collaborate with you, comprehensively understanding your business and all the factors that influence it.</Para1></Style>
        </StepWrapper>
                <StepWrapper1>
          
          <Hexagon downward id="hexagon2"  onMouseEnter={() => handleHexagonHover(2)}
              onMouseLeave={() => handleHexagonHover(null)} >
          <Logo1> <Service2 /></Logo1>
          
          </Hexagon>
     <StyledXarrow start="hexagon2" end="hexagon3" lineColor={hoveredStep === 2 ? '#FC9400' : 'white'}
              headColor={hoveredStep === 2 ? '#FC9400' : 'white'}  curveness={1} dashness={{ strokeLen: 10, nonStrokeLen: 5 }} strokeWidth={2}  />
          <Style2>  <Heading1 style={{ color: hoveredStep === 2 ? '#FC9400' : 'white' }}>Planning</Heading1>
      <Para1>In the planning stage, we develop, refine, and test our creative solutions, ideas, or concepts, exploring and fine-tuning to ensure optimal results.</Para1></Style2>
        </StepWrapper1>
        <StepWrapper>
       
          <Hexagon id="hexagon3" onMouseEnter={() => handleHexagonHover(3)}
              onMouseLeave={() => handleHexagonHover(null)}>
          <Logo1> <Service3 /></Logo1>
          </Hexagon>
         <StyledXarrow start="hexagon3" end="hexagon4" lineColor={hoveredStep === 3 ? '#FC9400' : 'white'}
              headColor={hoveredStep === 3 ? '#FC9400' : 'white'} curveness={1} dashness={{ strokeLen: 10, nonStrokeLen: 5 }} strokeWidth={2} />
         <Style3>  <Heading1 style={{ color: hoveredStep === 3 ? '#FC9400' : 'white' }}>Building</Heading1>
      <Para1>Take into account the utilization of structural building services for the project.</Para1></Style3>
        </StepWrapper>


        <StepWrapper1 >
        
          <Hexagon downward id="hexagon4" onMouseEnter={() => handleHexagonHover(4)}
              onMouseLeave={() => handleHexagonHover(null)}>
          <Logo1> <Service4/></Logo1>
          </Hexagon>
      <StyledXarrow start="hexagon4" end="hexagon5"  lineColor={hoveredStep === 4 ? '#FC9400' : 'white'}
              headColor={hoveredStep === 4 ? '#FC9400' : 'white'} curveness={1} dashness={{ strokeLen: 10, nonStrokeLen: 5 }} strokeWidth={2} />
          <Style1>  <Heading1 style={{ color: hoveredStep === 4 ? '#FC9400' : 'white' }}>Revision</Heading1>
         
      <Para1>We incorporate revisions with the aim of moving closer to the optimal end result for the client's project.</Para1></Style1>
        </StepWrapper1>

        <StepWrapper>
      
          <Hexagon id="hexagon5" onMouseEnter={() => handleHexagonHover(5)}
              onMouseLeave={() => handleHexagonHover(null)}>
          <Logo1> <Service5/></Logo1>
          </Hexagon>
   {/* <StyledXarrow start="Hexagon5" end="hexagon6"  lineColor={hoveredStep === 5 ? 'orange' : 'white'}
              headColor={hoveredStep === 5 ? 'orange' : 'white'} curveness={1}  dashness={{ strokeLen: 10, nonStrokeLen: 5 }} strokeWidth={2} />
   */}
          <Style>  <Heading1 style={{ color: hoveredStep === 5 ? '#FC9400' : 'white' }}>Launch</Heading1>
      <Para1>The product launch process demands meticulous planning and systematic execution to guarantee a positive reception from customers.</Para1></Style>
        </StepWrapper>

      </ProcessContainer>
      </StyledInfo12>
    </StyledInfo3>
  );
};

export default StepsProcess;