import React,{useState} from 'react'
import {StyledInfo1,StyledInfo2,StyledHeading,MainHeading,
    StyledButton,ButtonFirst,ButtonSecond,Button1,Button2} from './style';
import ContactFormPopup from '../Contact';

const Testimonial = () => {

    const [isFormOpen, setIsFormOpen] = useState(false);

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
            <StyledInfo1>
                <StyledInfo2>
                    <StyledHeading>
                        <MainHeading>DO YOU HAVE A PROJECT IN YOUR MIND?</MainHeading>
                    </StyledHeading>
                    <StyledButton>
                          {isFormOpen && <ContactFormPopup onClose={closeForm} />}
                        <ButtonFirst><Button1   onClick={openForm}    style={{ cursor: "pointer" }}>HIRE US</Button1></ButtonFirst>
                        <ButtonSecond><Button2  onClick={() => scrollToSection("Work")}
                  style={{ cursor: "pointer" }}>SEE OUR WORK</Button2></ButtonSecond>
                    </StyledButton>
                </StyledInfo2>
            </StyledInfo1>
  )
}

export default Testimonial