import React , { useState } from 'react'
import {StyledInfo1,Header,Navigation,Logo,Link,NavLogo,MobileIcon,Logo1} from './style'
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import ContactFormPopup from "../Contact/index";
const HeaderTop = () => {

  const [isFormOpen, setIsFormOpen] = useState(false);

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };

  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick(!click);
  };
  const closeMobileMenu = () => setClick(false);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      closeMobileMenu();
    }
  };

  return (
    <>
    <IconContext.Provider value={{  color: "FFFFFF" }}>
    <StyledInfo1>
    <Logo1><NavLogo to="/" src="../logo.png" /></Logo1>
    
    <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>

    <Header onClick={handleClick} click={click}>
      <Navigation>
      
        <Link  onClick={() => scrollToSection("Home")}
                  style={{ cursor: "pointer" }}>Home</Link>
        <Link   onClick={() => scrollToSection("Services")}
                  style={{ cursor: "pointer" }}>Services</Link>
        <Logo src="../logo.png" alt="Logo" />
        <Link   onClick={() => scrollToSection("Work")}
                  style={{ cursor: "pointer" }}>Work</Link>
        {isFormOpen && <ContactFormPopup onClose={closeForm} />}
        <Link href="#" onClick={openForm}>Contact Us</Link>
      </Navigation>
    </Header>
</StyledInfo1>
</IconContext.Provider>
  </>
  )
}

export default HeaderTop