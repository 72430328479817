import React, { useState } from 'react';
import { Gallery, GalleryImage } from 'react-gesture-gallery';
import { FullScreenOverlay, CloseButton } from './style';

const FullScreenGallery = ({ images, selectedImageIndex, closeGallery }) => {
  const [currentIndex, setCurrentIndex] = useState(selectedImageIndex);

  const handleRequestChange = (index) => {
    setCurrentIndex(index);
  };

  return (
    <FullScreenOverlay>
      <Gallery
        index={currentIndex}
        onRequestChange={handleRequestChange}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '75%' }}
      >
        {images.map((image, index) => (
          <GalleryImage key={index} src={image.image} alt={`Image ${index}`} />
        ))}
      </Gallery>

      <CloseButton onClick={closeGallery}>&#10006;</CloseButton>
    </FullScreenOverlay>
  );
};

export default FullScreenGallery;
