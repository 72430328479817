import styled from 'styled-components';

export const DetailInfo =styled.div`
margin:0;
padding:0;
display: flex;
align-items: center;
justify-content: center;
min-height:80vh;
`;
export const DetailDiv =styled.div`
display: flex;
align-items: center;
flex-direction: column;
justify-content: space-between;
`;
export const DetailItem=styled.div`
display:flex;
align-items:center;
justify-content: center;
flex-direction: column;
`;
export const DetailHeading=styled.div`
margin-top:2%;
`;
export const DetailH=styled.h1`
color:#03375e;
font-family:Montserrat;
font-weight:600;
font-size:40px;

@media only screen and (min-width:641px) and (max-width:810px){
    font-size: 32px;
    margin-left:-14%;
}
@media only screen and (min-width:320px) and (max-width:640px){
    font-size: 24px;
    margin-left:-2%;
}
`;
export const DetailFormHeading=styled.div`
margin-top:3%

`;
export const DetailForm=styled.div`
display: flex;
align-items: center;
margin-top:4%;
@media only screen and (min-width:641px) and (max-width:810px){
margin-left:-2%;
}
@media only screen and (min-width:320px) and (max-width:640px){
margin-left:-5%;
}
`;
export const DetailForm1=styled.div`
display: flex;
align-items: center;
margin-top:2%;
@media only screen and (min-width:641px) and (max-width:810px){
    margin-left:-2%;
}
@media only screen and (min-width:320px) and (max-width:640px){
    margin-left:-5%;
}
`;
export const DetailForm2=styled.div`
display: flex;
align-items: center;
margin-top:4%;
@media only screen and (min-width:641px) and (max-width:810px){
    margin-left:-2%;
}
@media only screen and (min-width:320px) and (max-width:640px){
    margin-left:-5%;
}
`;
export const DetailForm3=styled.div`
display: flex;
align-items: center;
margin-top:4%;
@media only screen and (min-width:641px) and (max-width:810px){
    margin-left:-2%;
}
@media only screen and (min-width:320px) and (max-width:640px){
    margin-left:-6%;
}
`;
export const Label=styled.label`
font-size: 22px;
color:#000000;
font-weight:600;
font-family: Montserrat;
width:60%;
@media only screen and (min-width:641px) and (max-width:810px){
    font-size: 18px;
}
@media only screen and (min-width:320px) and (max-width:640px){
    font-size: 16px;
}
`;
export const InputField =styled.input`
width: 490px;
height:56px;
border-radius:7px;
border:1px solid #16205D;
padding-left: 12px;
color:#000000;
font-family: 600;
font-size:16px;
font-family: Montserrat;
@media only screen and (min-width:641px) and (max-width:810px){
    width:300px;
    margin-left: -26%;
}
@media only screen and (min-width:320px) and (max-width:640px){
    width:150px;
    margin-left: -13%;
    font-size:12px;
}
`;
export const TextArea=styled.textarea`
width: 490px;
height: 92px;
border-radius:7px;
border:1px solid #16205D;
padding-left: 13px;
padding-top: 20px;
font-size: 16px;
@media only screen and (min-width:641px) and (max-width:810px){
    width: 300px;
    margin-left: -26%;
}
@media only screen and (min-width:320px) and (max-width:640px){
    width: 150px;
    margin-left: -13%;
}
`;
 
export const Btn=styled.div`
align-items: center;
display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8%;
    margin-left:-12%;
    @media only screen and (min-width:641px) and (max-width:810px){
        margin-top: 13%;
    margin-left:4%; 
    }
    @media only screen and (min-width:320px) and (max-width:640px){
        margin-top: 13%;
    margin-left:3%; 
    }
`;
export const Btn1=styled.div`
margin-left:-3%;
`;

export const Button=styled.button`
color:#ffffff;
background-color: #03375e;
width: 221px;
height: 59px;
border-radius:11px;
border:none;
font-family: 'Montserrat';
align-items: center;
text-align: center;
font-size:16px;
font-weight: 600;
cursor:pointer;
&:hover{
    background-color: #EE991C;
    border:none;
    outline: none;
    color:#000000;
}
@media only screen and (min-width:320px) and (max-width:640px){
    width: 150px;
height: 49px;
font-size:12px
    }
`;
export const Button1= styled.button`
color:#000000;
background-color: #92DFEF;
width: 221px;
height: 59px;
border-radius:11px;
border:none;
font-family: 'Montserrat';
align-items: center;
text-align: center;
font-size:16px;
font-weight: 600;
cursor:pointer;
&:hover{
    background-color: #EE991C;
    border:none;
    outline: none;
    color:#000000;
}
@media only screen and (min-width:320px) and (max-width:640px){
    width: 150px;
height: 49px;
font-size:12px
    }
`;