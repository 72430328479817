import React, { useEffect, useState } from "react";
import {
  basicWebsitePlan,
  blogSetupPackage,
  ecommerceStarterPack,
  smallBusiness,
} from "../../utils/content";

import Popup from "../PricingPopup";
import Modal from "../../Modal";
import { PriceCard } from "./PriceCard";
import {
  StyledA,
  StyledB,
  StyledButton,
  StyledButtonRow,
  StyledH1,
  StyledMain,
} from "./styles";

function PricingSection() {
  const handleDefaultButtonClick = (planValue) => {
    if (planValue === "Basic Plan") {
      setPlan(planValue);
      setCardData(basicWebsitePlan);
    }
    if (planValue === "E-Commerce") {
      setPlan(planValue);
      setCardData(ecommerceStarterPack);
    }
    if (planValue === "Portfolio") {
      setPlan(planValue);
      setCardData(ecommerceStarterPack);
    }
    if (planValue === "Blogs") {
      setPlan(planValue);
      setCardData(blogSetupPackage);
    }
    if (planValue === "Small Business") {
      setPlan(planValue);
      setCardData(smallBusiness);
    }
  };
  const [openPopup, setOpenPopup] = useState(false);
  const [plan, setPlan] = useState("Basic Plan");
  const [cardData, setCardData] = useState(basicWebsitePlan);
  const [options, setOptions] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [mostPopularCard, setMostPopularCard] = useState("Standard Plan");
  const handleModal = (data) => {
    setModalData(data);
    setOpenPopup(true);
  };
  useEffect(() => {
    let plansData = cardData.map((item, index) => {
      return (
        <PriceCard
          key={index}
          cardData={item}
          handleModel={handleModal}
          isMostPopular={item.name === mostPopularCard}
        />
      );
    });
    setOptions(plansData);
  }, [cardData]);
  const handleRedirect = (bool) => {
    if (bool) {
      setOpenPopup(true);
    }
  };
  return (
    <>
      <StyledMain id="Pricing">
        <StyledA>
          <StyledH1>Transparent Pricing for Exceptional Value</StyledH1>
          <StyledButtonRow>
            <StyledButton
              active={plan === "Basic Plan"}
              onClick={() => handleDefaultButtonClick("Basic Plan")}
            >
              Website Package
            </StyledButton>
            <StyledButton
              active={plan === "E-Commerce"}
              onClick={() => handleDefaultButtonClick("E-Commerce")}
            >
              E-Commerce
            </StyledButton>
            <StyledButton
              active={plan === "Portfolio"}
              onClick={() => handleDefaultButtonClick("Portfolio")}
            >
              Portfolio{" "}
            </StyledButton>
            <StyledButton
              active={plan === "Blogs"}
              onClick={() => handleDefaultButtonClick("Blogs")}
            >
              Blogs
            </StyledButton>
            <StyledButton
              active={plan === "Small Business"}
              onClick={() => handleDefaultButtonClick("Small Business")}
            >
              Small Business
            </StyledButton>
          </StyledButtonRow>
        </StyledA>
        <StyledB>{options}</StyledB>
      </StyledMain>
      {openPopup && (
        <Modal isOpen={openPopup}>
          <Popup
            cardData={modalData}
            handleClose={() => setOpenPopup(false)}
            handleRedirect={handleRedirect}
          />
        </Modal>
      )}
    </>
  );
}

export default PricingSection;
