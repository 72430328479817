import styled from "styled-components";

export const StyledMain = styled.div`
  width: 100%;
  // height: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const StyledSection1 = styled.div`
  width: 100%;
  // height: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("image1.png"), lightgray 50% / contain no-repeat;
`;
export const StyledText = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    flex-direction: column;
    justify-content: center;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    flex-direction: column;
    justify-content: center;
  }
`;
export const StyledText1 = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5%;
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    width: 70%;
    align-items: center;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    width: 90%;
    align-items: center;
  }
`;
export const StyledP = styled.p`
  color: #fc9400;
  font-family: Montserrat;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 39px */
  letter-spacing: -0.91px;
  text-transform: uppercase;
  margin: 0.2em;
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    font-size: 24px;
    letter-spacing: -0.84px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    font-size: 20px;
    letter-spacing: -0.7px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 16px;
    letter-spacing: -0.56px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    font-size: 16px;
    letter-spacing: -0.56px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    font-size: 14px;
    letter-spacing: -0.49px;
  }
`;
export const StyledH3 = styled.h3`
  color: #03375e;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 106%; /* 50.88px */
  letter-spacing: -1.68px;
  margin: 0.2em;
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    font-size: 42px;
    letter-spacing: -1.47px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    font-size: 40px;
    letter-spacing: -1.4px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 32px;
    letter-spacing: -1.12px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    font-size: 32px;
    letter-spacing: -1.12px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    font-size: 26px;
    letter-spacing: -0.91px;
  }
`;
export const StyledP1 = styled.p`
  width: 50%;
  color: #03375e;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  margin-top: 10%;
  letter-spacing: 0.3px;
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    font-size: 16px;
    letter-spacing: 0.24px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    font-size: 16px;
    letter-spacing: 0.24px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
    letter-spacing: 0.18px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    font-size: 12px;
    letter-spacing: 0.18px;
    width: 70%;
    text-align: center;
    margin-top: 0%;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    font-size: 12px;
    letter-spacing: 0.18px;
    width: 70%;
    text-align: center;
    margin-top: 0%;
  }
`;
export const StyledCardSection = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  // z-index: 1;
  top: 100px;
  position: relative;
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    top: 60px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    top: 60px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    top: 50px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    top: 30px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    top: 30px;
  }
`;
export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 3%;
`;
export const OverlayLink = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
  height: 276px;
  border-radius: 5px;
  border: 4px solid #7dd9d9;
  background-color: rgba(3, 55, 94, 0.92);
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    height: 248px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    height: 210px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 157px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    height: 109px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    height: 86px;
  }
`;
export const StyledP3 = styled.p`
  width: 60%;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 123%; /* 29.52px */
  letter-spacing: -0.96px;
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    font-size: 20px;
    letter-spacing: -0.8px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    font-size: 18px;
    letter-spacing: -0.72px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 14px;
    letter-spacing: -0.56px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    font-size: 13px;
    letter-spacing: -0.52px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    font-size: 12px;
    letter-spacing: -0.48px;
    width: 80%;
  }
`;
export const StyledP4 = styled.p`
  width: 70%;
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 156%; /* 18.72px */
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    font-size: 11px;
    line-height: 156%;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    font-size: 9px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 7px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    display: none;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    display: none;
  }
`;
export const StyledButton = styled.button`
  cursor: pointer;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: #fc9400;
  // stroke-width: 1px;
  // stroke: rgba(255, 255, 255, 0.04);
  // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    width: 41.026px;
    height: 40px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    width: 33px;
    height: 33px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 24px;
    height: 24px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    width: 23px;
    height: 23px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    width: 18.098px;
    height: 18.098px;
  }
`;
export const StyledIcon2 = styled.div`
  width: 20px;
  height: 20px;
  color: #fff;
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    width: 17px;
    height: 17px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    width: 15px;
    height: 15px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 12px;
    height: 12px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    width: 10px;
    height: 10px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    width: 9px;
    height: 9px;
  }
`;
export const StyledCard = styled.div`
  width: 30%;
  height: 345px;
  flex-shrink: 0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(image2.png) lightgray 50% / cover no-repeat;

  box-shadow: 0px 5px 17.6px 3px rgba(0, 0, 0, 0.18);
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: linear-gradient(
    //   180deg,
    //   rgba(3, 55, 94, 0.99) 0%,
    //   rgba(30, 57, 77, 0.99) 100%
    // );
    z-index: -1;
  }
  &:hover ${OverlayLink} {
    opacity: 1;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    height: 289px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    height: 246px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 185px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    height: 128px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    width: 45%;
    height: 100px;
  }
`;
export const StyledCard1 = styled.div`
  width: 30%;
  height: 345px;
  flex-shrink: 0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("image5.png") lightgray 50% / cover no-repeat;
  box-shadow: 0px 5px 17.6px 3px rgba(0, 0, 0, 0.18);
  &:hover ${OverlayLink} {
    opacity: 1;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    height: 289px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    height: 246px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 185px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    height: 128px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    width: 45%;
    height: 100px;
  }
`;
export const StyledCard2 = styled.div`
  width: 30%;
  height: 345px;
  flex-shrink: 0;
  border-radius: 5px;
  background: url("image6.png") lightgray 50% / cover no-repeat;
  box-shadow: 0px 5px 17.6px 3px rgba(0, 0, 0, 0.18);
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover ${OverlayLink} {
    opacity: 1000;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    height: 289px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    height: 246px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 185px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    height: 128px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    display: none;
  }
`;
export const StyledCard3 = styled.div`
  width: 30%;
  height: 345px;
  flex-shrink: 0;
  border-radius: 5px;
  background: url("image7.png") lightgray 50% / cover no-repeat;
  box-shadow: 0px 5px 17.6px 3px rgba(0, 0, 0, 0.18);
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover ${OverlayLink} {
    opacity: 1;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    height: 289px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    height: 246px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 185px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    height: 128px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    height: 100px;
    width: 45%;
  }
`;
export const StyledCard4 = styled.div`
  width: 30%;
  height: 345px;
  flex-shrink: 0;
  border-radius: 5px;
  background:  url("image9.png") lightgray 50% / cover no-repeat;
  box-shadow: 0px 5px 17.6px 3px rgba(0, 0, 0, 0.18);
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover ${OverlayLink} {
    opacity: 1;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    height: 289px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    height: 246px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 185px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    height: 128px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    height: 100px;
    width: 45%;
  }
`;
export const StyledCard5 = styled.div`
  width: 30%;
  height: 345px;
  flex-shrink: 0;
  border-radius: 5px;
  background: url("image8.png") lightgray 50% / cover no-repeat;
  box-shadow: 0px 5px 17.6px 3px rgba(0, 0, 0, 0.18);
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover ${OverlayLink} {
    opacity: 1000;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    height: 289px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    height: 246px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 185px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    height: 128px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    display: none;
  }
`;
export const StyledCard6 = styled.div`
  width: 30%;
  height: 345px;
  flex-shrink: 0;
  border-radius: 5px;
  background: url("image12.png") lightgray 50% / cover no-repeat;
  box-shadow: 0px 5px 17.6px 3px rgba(0, 0, 0, 0.18);
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover ${OverlayLink} {
    opacity: 1000;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    height: 289px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    height: 246px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 185px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    height: 128px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    display: none;
  }
`;
export const StyledCard7 = styled.div`
  width: 30%;
  height: 345px;
  flex-shrink: 0;
  border-radius: 5px;
  background: url("image13.png") lightgray 50% / cover no-repeat;
  box-shadow: 0px 5px 17.6px 3px rgba(0, 0, 0, 0.18);
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover ${OverlayLink} {
    opacity: 1000;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    height: 289px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    height: 246px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 185px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    height: 128px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    display: none;
  }
`;
export const StyledSection2 = styled.div`
  width: 100%;
  height: 500px;
  // background: url("image3.png"), lightgray 50% / cover no-repeat;
  display: flex;
  // flex-direction: row;
  align-items: center;
  justify-content: center;
  background-image: url("../bk.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  position: relative;
  z-index: -1;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(3, 55, 94, 0.99) 0%,
      rgba(30, 57, 77, 0.99) 100%
    );
    z-index: -1;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    height: 400px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    height: 346px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 300px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    height: 239px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    height: 285px;
  }
`;
export const StyledInfo = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;
export const StyledIconSection = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const StyledIcon = styled.div`
  width: 96.174px;
  height: 96.174px;
  // transform: rotate(179.398deg);
  flex-shrink: 0;
  border-radius: 129px;
  background: linear-gradient(
    8deg,
    rgba(255, 255, 255, 0.45) -2.69%,
    rgba(125, 217, 217, 0) 95.84%
  );
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    width: 80.583px;
    height: 80.583px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    width: 68.507px;
    height: 68.507px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 51.32px;
    height: 51.32px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    width: 40.291px;
    height: 40.291px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    width: 40.291px;
    height: 40.291px;
  }
`;
export const StyledIcon1 = styled.div`
  width: 69.27px;
  height: 69.27px;
  flex-shrink: 0;
  color: #fc9400;
  margin-left: 37%;
  margin-top: 32%;
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    width: 63.679px;
    height: 63.67px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    width: 49.343px;
    height: 49.343px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 36.963px;
    height: 36.963px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    width: 29.02px;
    height: 29.02px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    width: 29.02px;
    height: 29.02px;
  }
`;
export const StyledH2 = styled.h2`
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 70px;
  font-style: normal;
  font-weight: 600;
  line-height: 131.5%; /* 92.05px */
  letter-spacing: -2.8px;
  margin: 0.2rem;
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    font-size: 60px;
    letter-spacing: -2.4px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    font-size: 48px;
    letter-spacing: -1.92px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 36px;
    letter-spacing: -1.44px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    font-size: 32px;
    letter-spacing: -1.28px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    font-size: 32px;
    letter-spacing: -1.28px;
  }
`;
export const StyledP2 = styled.p`
  color: rgba(255, 255, 255, 0.77);
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 131.5%; /* 26.3px */
  letter-spacing: -0.8px;
  margin: 0.2rem;
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    font-size: 18px;
    letter-spacing: -0.72px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    font-size: 15px;
    letter-spacing: -0.6px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
    letter-spacing: -0.48px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    font-size: 12px;
    letter-spacing: -0.48px;
  }
`;
