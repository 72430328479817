import React, { useState } from "react";
import ContactFormPopup from "../Contact/index";
import {
  A,
  StyledA,
  StyledB,
  StyledBtn,
  StyledColumn,
  StyledColumn1,
  StyledContent,
  StyledH3,
  StyledInput,
  StyledInputRow,
  StyledLine,
  StyledLogo,
  StyledMain,
  StyledP,
  StyledP1,
  StyledROw,
  StyledText,
  StyledBtnA,
  IconRow,
  StyledIcon,
  StyledIcon2,
  StyledIcon3,
  StyledIcon4,
  StyledIcon1,
  StyledIcon5,
  StyledScroll,
  svgTwitter,
  svgInsta,
  svgLinkIn,
  svgFBook,
  svg,
  svgYoutube,
  StyledMainHeading1,
  StyledMainHeading2,
  StyledMainHeading3,
  StyledBelow,
  StyledScroll1,
  StyledIcon12,
  StyledIcon13,
  StyledIcon14,
  StyledScroll12,
} from "./style";
import Section8 from "../Section8";
function FooterSection() {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <StyledMain>
      <Section8 />
      <StyledContent>
        <StyledROw>
          <StyledA>
            <StyledLogo src="../footerlogo.png" alt="img"></StyledLogo>
            <StyledP>
              We're a team of experienced graphic designers, illustrators, and
              branding experts, dedicated to crafting visually stunning and
              impactful digital solutions.
            </StyledP>
            <IconRow>
              <A href="https://www.instagram.com/liam.crest.corp/">
                <StyledIcon>
                  <div dangerouslySetInnerHTML={{ __html: svgInsta }} />
                </StyledIcon>
              </A>
              <A href="https://twitter.com/LiamCrestCorp">
                <StyledIcon5>
                  <div dangerouslySetInnerHTML={{ __html: svgTwitter }} />
                </StyledIcon5>
              </A>
              <A href="https://www.linkedin.com/company/liamcrest">
                <StyledIcon1>
                  <div dangerouslySetInnerHTML={{ __html: svgLinkIn }} />
                </StyledIcon1>
              </A>
              <A href="https://www.facebook.com/liamcrestcorp?_rdc=2&_rdr">
                <StyledIcon2>
                  <div dangerouslySetInnerHTML={{ __html: svgFBook }} />
                </StyledIcon2>
              </A>
              <A href="https://www.behance.net/designers-liamcrest">
                <StyledIcon3>
                  <div dangerouslySetInnerHTML={{ __html: svg }} />
                </StyledIcon3>
              </A>
              <A href="https://www.youtube.com/channel/UCVYVnbFIbWQ4a3MfPyMhQHw">
                <StyledIcon4>
                  <div dangerouslySetInnerHTML={{ __html: svgYoutube }} />
                </StyledIcon4>
              </A>
            </IconRow>
          </StyledA>
          <StyledB>
            <StyledColumn>
              <StyledMainHeading1> Our Comapny</StyledMainHeading1>
              <StyledScroll onClick={() => scrollToSection("Home")}>
                Home
              </StyledScroll>

              <StyledScroll onClick={() => scrollToSection("about")}>
                About us
              </StyledScroll>
              <StyledScroll onClick={() => scrollToSection("Services")}>
                Services
              </StyledScroll>
            </StyledColumn>
            <StyledColumn>
              <StyledMainHeading2> Quick Links</StyledMainHeading2>
              <StyledScroll onClick={() => scrollToSection("Work")}>
                Projects
              </StyledScroll>
              <StyledScroll onClick={() => scrollToSection("Pricing")}>
                Pricing
              </StyledScroll>
              {isFormOpen && <ContactFormPopup onClose={closeForm} />}
              <StyledScroll onClick={openForm}>Contact</StyledScroll>
            </StyledColumn>
            <StyledColumn1>
              <StyledColumn>
                <StyledMainHeading3>Contact Information</StyledMainHeading3>
                <StyledScroll1>
                  <StyledIcon12 src="../loc.png" alt="icon" /> Memphis, TN and
                  Phoenix, AZ
                </StyledScroll1>
                <StyledScroll1>
                  <StyledIcon13 src="../ph.png" alt="icon" /> 888 491 5293
                </StyledScroll1>
                <StyledScroll1>
                  <StyledIcon14 src="../ms.png" alt="icon" />{" "}
                  Sales@liamcrest.com
                </StyledScroll1>
              </StyledColumn>
            </StyledColumn1>
          </StyledB>
        </StyledROw>
        <StyledBelow>
          <StyledLine></StyledLine>
          <StyledText>
            <StyledP1>
              © 2024 — <span> Liam Crest.</span>All Rights Reserved.
            </StyledP1>
          </StyledText>
        </StyledBelow>
      </StyledContent>
    </StyledMain>
  );
}

export default FooterSection;
