import logo from "./logo.svg";
import Section1 from "./components/Section1/index";
import Section4 from "./components/Section4";
import Section6 from "./components/section6";
import Section7 from "./components/Section7";
import Section8 from "./components/Section8";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import Home from "./components/Pages/Home/Home";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Popup from "./components/PricingPopup";
import DetailForms from "./components/DetailForm";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact Component={Home} />
        <Route path="/section1" element={<Section1 />} />
        <Route path="/section4" element={<Section4 />} />
        <Route path="/section6" element={<Section6 />} />
        <Route path="/section7" element={<Section7 />} />
        <Route path="/section8" element={<Section8 />} />
        <Route path="/Footer" element={<Footer />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/popup" element={<Popup />} />
        <Route path="/detailform" element={<DetailForms/>} />
      </Routes>
    </Router>
  );
}

export default App;
