import React from 'react';
import {SectionContainer,Heading,InputFieldWrapper,InputField,Button,Heading1,Heading2,Headfirst,HeadSecond} from './style';



const ContactUs = () => {
  return (
    <SectionContainer>
    <Heading1>
     <Headfirst> <Heading>Ready to get started?</Heading></Headfirst>
      <HeadSecond><Heading2>Talk to us today.</Heading2></HeadSecond>
      </Heading1>
      <InputFieldWrapper>
        <InputField type="text" placeholder="Enter your email " />
        <Button>Submit</Button>
      </InputFieldWrapper>
    </SectionContainer>
  );
};

export default ContactUs;
