import styled from "styled-components";

export const StyledMain = styled.div`
  width: 100%;
  // height: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("image.png") center / cover;
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    // background: linear-gradient(
    //   180deg,
    //   rgba(3, 55, 94, 0.99) 0%,
    //   rgba(30, 57, 77, 0.99) 100%
    // );
    z-index: -1;
  }
`;
export const StyledDesign = styled.div`
    width: 85%;
    height: 550px;
    
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    // background: #fff;
    background:  white 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    // justify-content: center;
    background-position: top;
    /* position: relative;
    top: 44%; */
    margin:-10% 0;
    border-radius: 7px;
    @media only screen and (min-width:1081px) and (max-width:1280px){
      height:580px;
    }
    @media only screen and (min-width:811px) and (max-width:1080px){
      height:426px;
      
    }
    @media only screen and (min-width:641px) and (max-width:810px){
   display: flex;
   align-items: center;
   justify-content: center;
      flex-direction :column-reverse ;
   height:595px;
   margin:-14% 0;
   
  }
  @media only screen and (min-width:320px) and (max-width:640px){
   display: flex;
   align-items: center;
   justify-content: center;
      flex-direction :column-reverse ;
   height:630px;
   margin:-10% 0;
  }
`;
export const StyledBackground = styled.img`
  width: 60%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container */
  object-position: left;
  position: relative;
  top: -15%; /* Adjust the negative value based on your requirements */
  right: 5%;
  @media only screen and (min-width:1081px) and (max-width:1280px){
    width: 60%;
  height: 86%;
  top:-21%;
  }
  @media only screen and (min-width:811px) and (max-width:1080px){
    width: 60%;
  height: 86%;
  top:-21%;
  }
  @media only screen and (min-width:641px) and (max-width:811px){
    width: 75%;
  height: 100%;
  right:0;

  }
  @media only screen and (min-width:320px) and (max-width:640px){
    width: 84%;
  height: 100%;
  top:-8%;
align-items: center;
justify-content:center;
right:0;
  }
`;
export const StyledTextSection = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 2%;
  position:relative;
  left:-7%;
  @media only screen and (min-width:1081px) and (max-width:1280px){
    margin-right: 1%;
    width:45%;
  }
  @media only screen and (min-width:811px) and (max-width:1080px){
    margin-right: 1%;
    width:45%;
  }
  @media only screen and (min-width:641px) and (max-width:810px){
    width: 65%;
    align-items: center;
    justify-content: center;
    left:0;
  }
  @media only screen and (min-width:320px) and (max-width:640px){
    width: 98%;
    align-items: center;
    justify-content: center;
    left:0;
  }
`;
export const StyledButton=styled.div`
display: flex;
align-items:center;
justify-content:center;
`;
export const Button1=styled.button`
width:275.73px;
height:64.19px;
border-radius: 33px;
background-color: rgba(3, 55, 94, 1);
border:none;
font-size: 20px;
font-family:'Montserrat';
line-height: 30px;
color:#FFFFFF;
font-weight: 500;
cursor:pointer;
&:hover{
  background:#fc9400;
  transition: 0.9s;
} 
@media only screen and (min-width:1081px) and (max-width:1280px){
  width:196.16px;
height:46.19px;
font-size: 15px;
     }
     @media only screen and (min-width:811px) and (max-width:1080px){
      width:155px;
height:34px;
font-size: 12px;
     }
     @media only screen and (min-width:641px) and (max-width:810px){
      width:155px;
height:34px;
font-size: 12px;
     }
     @media only screen and (min-width:320px) and (max-width:640px){
      width:152px;
height:31px;
font-size: 12px;
line-height: 18px;
     }
     `;
export const StyledH1 = styled.h1`
  color: #03375e;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 114%; /* 57px */
  letter-spacing: -1.75px;
  & span {
    color: #fc9400;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 114%;
    letter-spacing: -1.75px;
  }
  @media only screen and (min-width:1081px) and (max-width:1280px){
    font-size: 35px;
    line-height: 46.62px;
    & span {
      font-size: 35px;
    line-height: 46.62px;
    }
     }
     @media only screen and (min-width:811px) and (max-width:1080px){
    font-size: 28px;
    line-height: 35.52px;
    & span {
      font-size: 28px;
    line-height: 35.52px;
    }
     }
     @media only screen and (min-width:641px) and (max-width:810px){
    font-size: 28px;
    line-height: 35.52px;
    text-align: center;
    & span {
      font-size: 28px;
    line-height: 35.52px;
    text-align: center;
    }
     }
     @media only screen and (min-width:320px) and (max-width:640px){
    width:206px;
      font-size: 17px;
    line-height: 26.64px;
    text-align: center;
    & span {
      width:206px;
      font-size: 17px;
    line-height:26.64px;
    text-align: center;
    }
     }
`;
export const StyledP5 = styled.p`
  color: #03375e;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 167%; /* 33.4px */
  letter-spacing: 0.3px;
  & span {
    color: #03375e;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 167%;
    letter-spacing: 0.3px;
  }
  @media only screen and (min-width:1081px) and (max-width:1280px){
    font-size: 16px;
    line-height: 26.72px;
    & span {
      font-size: 16px;
    line-height: 26.72px;
    }
     }
     @media only screen and (min-width:811px) and (max-width:1080px){
      font-size: 12px;
    line-height: 20.72px;
    & span {
      font-size: 12px;
    line-height: 20.72px;
    }
     }
     @media only screen and (min-width:641px) and (max-width:810px){
      font-size: 12px;
      width:100%;
    line-height: 20.72px;
    text-align: center;
    & span {
      font-size: 12px;
    line-height: 20.72px;
    text-align:center;
    }
     }
     @media only screen and (min-width:320px) and (max-width:640px){
      font-size: 10px;
      width:100%;
    line-height: 17.72px;
    text-align: center;
    width:256px;
    & span {
      font-size: 10px;
      width:256px;
    line-height: 17.72px;
    text-align:center;
    }
     }
     
`;
export const StyledButton2 = styled.button`
  width: 275.375px;
  height: 64.679px;
  flex-shrink: 0;
  border-radius: 33px;
  background: #03375e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
`;
export const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8%;
`;
export const StyledP = styled.p`
  color: #fc9400;
  text-align: center;
  font-family: Montserrat;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 39px */
  letter-spacing: -0.91px;
  margin: 0.2rem;
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    font-size: 24px;
    letter-spacing: -0.84px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    font-size: 20px;
    letter-spacing: -0.7px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 16px;
    letter-spacing: -0.56px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    font-size: 16px;
    letter-spacing: -0.56px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    font-size: 14px;
    letter-spacing: -0.49px;
  }
`;
export const StyledH3 = styled.h3`
  width: 70%;
  color: #03375e;
  text-align: center;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 105%; /* 50.4px */
  letter-spacing: -1.92px;
  margin: 0.5rem;
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    font-size: 42px;
    letter-spacing: -1.68px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    font-size: 40px;
    letter-spacing: -1.6px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 32px;
    letter-spacing: -1.28px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    font-size: 32px;
    letter-spacing: -1.28px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    font-size: 26px;
    letter-spacing: -1.04px;
  }
`;
export const StyledServices = styled.div`
  width: 90%;
  // height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
`;
export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 5%;
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    flex-direction: column;
    justify-content: center;
    margin-top: 0%;
  }
`;
export const StyledRow1 = styled.div`
  display: none;
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    display: block;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: initial;
    margin-top: 2%;
    gap: 5%;
  }
`;
export const StyledCard = styled.div`
  width: 22%;
  height: 290px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 1s ease;
  gap: 40px;
  ${({ backgroundImage }) =>
    backgroundImage && `background-image: url(${backgroundImage});`}
  &:hover {
    transform: translateY(-2%);
    width: 22%;
    height: 290px;
    // transform: rotate(-90deg);
    flex-shrink: 0;
    border-radius: 3px;
    background: linear-gradient(102deg, #03375e 4.43%, #7dd9d9 287.15%);
    mix-blend-mode: multiply;
    box-shadow: 0px 42px 30.3px 0px rgba(0, 0, 0, 0.06);
  }
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    height: 243px;
    &:hover {
      height: 243px;
    }
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    height: 208px;
    gap: 20px;
    &:hover {
      height: 208px;
    }
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 157px;
    gap: 20px;
    &:hover {
      height: 157px;
    }
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    height: 160.535px;
    width: 30%;
    gap: 20px;
    &:hover {
      width: 30%;
      height: 160px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    width: 90%;
    height: 105px;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 2%;
    &:hover {
      width: 90%;
      height: 105px;
    }
  }
`;
export const StyledCard1 = styled.div`
  width: 22%;
  height: 290px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 1s ease;
  gap: 40px;
  &:hover {
    transform: translateY(-2%);
    width: 22%;
    height: 290px;
    // transform: rotate(-90deg);
    flex-shrink: 0;
    border-radius: 3px;
    background: linear-gradient(102deg, #03375e 4.43%, #7dd9d9 287.15%);
    mix-blend-mode: multiply;
    box-shadow: 0px 42px 30.3px 0px rgba(0, 0, 0, 0.06);
  }
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    height: 243px;
    &:hover {
      height: 243px;
    }
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    height: 208px;
    gap: 20px;
    &:hover {
      height: 208px;
    }
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 157px;
    gap: 20px;
    &:hover {
      height: 157px;
    }
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    display: none;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    width: 90%;
    height: 105px;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 2%;
    &:hover {
      width: 90%;
      height: 105px;
    }
  }
`;
export const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124.564px;
  height: 124.564px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 1px solid #b7b7b7;
  background: #fff;
  ${StyledCard}:hover & {
    border: 1px solid #a56e1e;
    background: linear-gradient(163deg, #fc9400 11.44%, #a56e1e 99.35%);
  }
  ${StyledCard1}:hover & {
    border: 1px solid #a56e1e;
    background: linear-gradient(163deg, #fc9400 11.44%, #a56e1e 99.35%);
  }
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    width: 104px;
    height: 105px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    width: 87px;
    height: 88px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 64px;
    height: 66px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    width: 65.441px;
    height: 67.486px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    width: 66px;
    height: 66px;
    margin-left: 5%;
  }
`;
export const StyledIcon1 = styled.div`
  width: 69px;
  height: 69px;
  flex-shrink: 0;
  color: #03375e;
  ${StyledCard}:hover & {
    color: #fff;
    width: 81.327px;
    height: 81.327px;
  }
  ${StyledCard1}:hover & {
    color: #fff;
    width: 81.327px;
    height: 81.327px;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 57px;
    height: 58px;
    ${StyledCard}:hover & {
      width: 68.143px;
      height: 68.143px;
    }
    ${StyledCard1}:hover & {
      width: 68.143px;
      height: 68.143px;
    }
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    width: 49px;
    height: 49px;
    ${StyledCard}:hover & {
      width: 57.143px;
      height: 57.143px;
    }
    ${StyledCard1}:hover & {
      width: 57.143px;
      height: 57.143px;
    }
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 35px;
    height: 37px;
    ${StyledCard}:hover & {
      width: 43.143px;
      height: 43.143px;
    }
    ${StyledCard1}:hover & {
      width: 43.143px;
      height: 43.143px;
    }
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    width: 35.788px;
    height: 37.833px;
    ${StyledCard}:hover & {
      width: 44.374px;
      height: 44.374px;
    }
    ${StyledCard1}:hover & {
      width: 44.374px;
      height: 44.374px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    width: 37px;
    height: 37px;
    ${StyledCard}:hover & {
      width: 43.143px;
      height: 43.143px;
    }
    ${StyledCard1}:hover & {
      width: 43.143px;
      height: 43.143px;
    }
  }
`;
export const StyledP1 = styled.div`
  color: #03375e;
  width: 80%;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 131.5%; /* 26.3px */
  letter-spacing: -0.8px;
  ${StyledCard}:hover & {
    color: #fff;
  }
  ${StyledCard1}:hover & {
    color: #fff;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 18px;
    letter-spacing: -0.72px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    font-size: 18px;
    letter-spacing: -0.72px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
    letter-spacing: -0.48px;
  }
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    font-size: 12px;
    letter-spacing: -0.48px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    font-size: 12px;
    letter-spacing: -0.48px;
    width: 40%;
  }
`;
