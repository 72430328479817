import styled from 'styled-components';

export const FullScreenOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0,0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const GalleryContent = styled.div`
  max-width: 80%;
  max-height: 80%;
  overflow: hidden;
  position: relative;
`;

export const StyledGalleryImage = styled.img`
  width: 100%;
  height: auto;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
`;

export const LeftArrow = styled.div`
  position: absolute;
  bottom: 25px; 
  left: 25%;
  color: white;
  font-size: 24px;
  cursor: pointer;
`;

export const RightArrow = styled.div`
  position: absolute;
  bottom: 25px;  
  right: 25%;
  color: white;
  font-size: 24px;
  cursor: pointer;
`;
